/**
 * Media
 *
 * @format
 */

export async function deleteMedia({ id }, confirm = false) {
  const { ui, t, performing } = this.props;
  if (!confirm) {
    this.unit.report({
      method: "deleteMedia",
      analyze: true,
      action: true,
      test: "When the delete icon is pressed a confirmation dialog should be shown, ",
    });
    ui.confirm.open({
      title: t("confirm"),
      text: t("confirmDeleteMedia"),
      dismiss: {
        onClick: () => {
          this.unit.report({
            method: "deleteMedia",
            analyze: true,
            action: true,
          });
        },
        text: t("cancel"),
      },
      confirm: {
        onClick: () => {
          performing.set.updating("updating", "~31");
          deleteMedia.call(this, { id: id }, true);
        },
        text: t("confirm"),
        color: "error",
      },
    });
  } else {
    this.unit.report({
      method: "deleteMedia",
      event: "confirmDeleteMedia",
      analyze: true,
      action: true,
      payload: { type: this.state.type, id: id },
      test: "After clicking confirm, the user's media should be deleted",
    });

    // # TODO DELETE FROM PROJECT

    await this.props.media.delete(id);

    performing.set.updating("success", "~52");
  }
}

export function canDelete(media) {
  const { user } = this.props;
  return user.admin() || media.userId == user.id;
}

export function onSuccess() {}
