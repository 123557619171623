/** @format */
import { Alert, Box } from "@mui/material";
import { withLocales } from "locales";
import { Component as ReactComponent } from "react";

import { exception } from "sentry";

class ComponentErrorComponent extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error) {
    this.setState({
      error: true,
    });
    try {
      exception(error);
    } catch (_) {}
  }

  render() {
    const { children, t, fallback } = this.props;
    if (this.state.error)
      return !fallback ? (
        <Box sx={{ padding: "20px" }}>
          <Alert severity="error">
            {t("unexpectedError")} {this.props.message && <>[{this.props.message}]</>}
          </Alert>
        </Box>
      ) : (
        <>{fallback}</>
      );

    return children ? children : <></>;
  }
}

export default withLocales(ComponentErrorComponent);
