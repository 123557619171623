/** @format */
import { withWorkspace } from "@Workspace/Context/";
import { withErrors } from "errors";
import { withLocales } from "locales";
import debounce from "lodash/debounce";
import { withMedia } from "media";
import { withPerforming } from "performing";
import { Component as ReactComponent, createContext, useContext } from "react";
import { withSnackbar } from "snackbar";
import { withUI } from "ui";
import { withUnit } from "unit";
import { withUser } from "user";

import Setup from "./helpers/Setup";

// Media ops
import { canDelete, deleteMedia, onSuccess } from "./helpers/Media";

// Where media gets uploaded to (workspace/public)
import { getTo, setTo, toggleTo } from "./helpers/To";

// What type of media is being uploaded (image/video)
import { getType, setType } from "./helpers/Type";

// Where media is being uploaded from (media/workspace)
import { getFrom, setFrom } from "./helpers/From";

const ElementsContext = createContext({});

class Elements extends ReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      queries: [],
      queried: false,
      type: "image",
      menu: { upload: false },
      from: "media",
      to: "workspace", // workspace, public
      filters: {
        search: "",
        collections: [],
        tags: [],
      },
    };

    this.types = {};

    try {
      this.queried = false;

      this.unit = this.props.unit.new(this.constructor.name);

      this.updateFormThrottled = debounce(() => {
        // console.log("update throttled.");
      }, 2000);
    } catch (e) {
      console.error(e, "~60");
    }

    this.from = { get: getFrom.bind(this), set: setFrom.bind(this) };

    this.to = { set: setTo.bind(this), get: getTo.bind(this), toggle: toggleTo.bind(this) };

    this.type = { set: setType.bind(this), get: getType.bind(this) };

    this.remove = {
      able: canDelete.bind(this),
      media: deleteMedia.bind(this),
    };

    this.onSuccess = onSuccess.bind(this);
  }

  /* Setup */

  setup = async () => {
    const { user, snackbar, t, workspace, media } = this.props;

    if (this.state.ready) return;

    try {
      const response = await Setup(this.props, "~85");

      this.types = response;

      const queries = [
        {
          name: "uploads",
          field: "userId",
          eq: "==",
          value: user.id,
          attachedTo: [workspace.id],
          collections: ["uploads"],
        },
        {
          name: "madelive",
          collections: ["madelive"],
        },
        {
          name: "generated",
          field: "userId",
          eq: "==",
          value: user.id,
        },
      ];

      const results = await Promise.all(
        queries.map((query) =>
          media.query(query).then((result) => {
            return result;
          })
        )
      );

      const hasRanAtLeastOneQuery = results.some((result) => !!result); // checks if at least one query has run

      if (!hasRanAtLeastOneQuery) {
        snackbar.open(t("troubleGettingMedia"), "warning");
      }

      if (results) this.setState({ ready: true, queries: queries.map((query) => query.name) });
    } catch (error) {
      console.error("Error during setup", error);
    }
  };

  /* React */

  componentDidMount() {
    if (!this.state.ready) this.setup();
  }

  render() {
    const { media, workspace, user } = this.props;

    return (
      <ElementsContext.Provider
        {...this.props}
        value={{
          ...this.state,
          media: { ...media, queries: this.state.queries },
          admin: user.admin,
          from: this.from,
          to: this.to,
          remove: this.remove,
          type: this.type,
          types: this.types,
          upload: {
            type: this.state.type,
            directory: this.state.to == "workspace" ? "/" : "/",
            collections: this.state.to == "workspace" ? ["uploads"] : ["madelive"],
            attachedTo: [workspace.id, user.id],
            userId: this.state.to == "workspace" ? user.id : user.id,
            onSuccess: this.onSuccess,
            shared: this.state.to == "workspace" ? false : true,
          },
        }}
      >
        {this.props.children}
      </ElementsContext.Provider>
    );
  }
}

const withElements = (Component) => {
  return function ContextualComponent(props) {
    return <ElementsContext.Consumer>{(state) => <Component {...props} elements={state} />}</ElementsContext.Consumer>;
  };
};

const useElements = () => {
  const context = useContext(ElementsContext);
  return context;
};

export default withPerforming(
  withUser(withLocales(withUnit(withWorkspace(withMedia(withErrors(withSnackbar(withUI(Elements))))))))
);

export { useElements, withElements };
