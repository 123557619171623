/** @format */

import Config from "@Workspace/config";

import { Functional } from "unit";

const unit = Functional.unit("workspace/checklist");

/**
 * Sets up the checklist.
 *
 * @returns {any} The result of the calculateChecklist function.
 */
export default function setupChecklist() {
  return calculateChecklist.call(this);
}

/**
 * Calculates the checklist progress and updates the state.
 * @returns {Promise<boolean>} A promise that resolves to true if the calculation is successful.
 */
export function calculateChecklist() {
  return new Promise((resolve, reject) => {
    try {
      // let project = cloneDeep(this.state.project);
      let initialCount = { total: 0, complete: 0 };
      let count = {
        checklist: initialCount,
        feature: initialCount,
      };
      let features = {};
      let checklist = {};

      let time = [0, 0];

      // Get the available features
      Object.values(Config.features)
        .sort((a, b) => a.priority - b.priority)
        .filter((feature) => feature.enabled === true)
        .forEach((feature) => {
          try {
            features[feature.slug] = {
              feature: Config.features[feature.slug],
              checklist: Config.checklist[feature.slug],
              steps: {},
            };
          } catch (error) {}
        });

      Object.keys(features).forEach((feature) => {
        checklist[feature] = {
          slug: feature,
          ...features[feature].checklist,
          ...{
            progress: {
              complete: 0,
              percent: 0,
              remaining: 0,
              total: Object.keys(features[feature].checklist).length,
            },
            time: [0, 0],
          },
        };

        let check = {
          completed: false,
          enabled: false,
        };

        count.feature = { total: 0, complete: 0 };

        let validation = false;
        let featureTime = [0, 0];

        Object.keys(checklist[feature].steps).forEach((step, i) => {
          if (checklist[feature].steps[step] == undefined) return;
          try {
            if (features[feature].checklist.steps[step] === true) {
              check.completed = true;
            } else if (features[feature].checklist.steps[step].enabled) {
              const complete = features[feature].checklist.steps[step].complete;

              validation = "";
              if (!complete) {
                check.completed = true;
              } else {
                if (typeof complete == "boolean") {
                  return complete;
                } else if (typeof complete == "string") {
                  if (complete.startsWith("step.")) {
                    validation = "features." + feature + "." + complete.replace("step.", "checklist.");
                  } else if (complete.startsWith("data.")) {
                    validation = "features." + feature + "." + complete;
                  } else if (complete.startsWith("project.")) {
                    validation = complete;
                  } else if (complete == "shared") {
                    validation = "shared.includes('" + feature + "')";
                  } else if (complete == "kb") {
                    validation = "kb.includes('" + features[feature].checklist.steps[step].kb + "')";
                  }
                } else {
                  return false;
                }

                check.completed = this.checklist.step.completed(validation.replace(/^\./, "")) || false;
              }
            }
          } catch (error) {
            console.warn(error.message, feature, "~108");
          }

          // check.enabled = features[feature].checklist.steps[step];
          // #TODO: Make sure this step is enabled (use evaluate)
          check.enabled = true;

          if (!this.features[feature].checklist) this.features[feature].checklist = {};

          checklist[feature].steps[step] = {
            slug: step,
            completed: check.completed || features[feature].checklist.steps[step].optional || false,
            enabled: check.enabled,
            complete: features[feature].checklist.steps[step].complete,
            optional: features[feature].checklist.steps[step].optional || false,
            checklist: feature, // in the event the featuer is nested, we need to reference this.
            manual:
              features[feature].checklist.steps[step].complete &&
              features[feature].checklist.steps[step].complete.includes(`step.${step}`),
            visible:
              features[feature].checklist.steps[step].visible != null
                ? features[feature].checklist.steps[step].visible
                : true,
            title: features[feature].checklist.steps[step].title,
            actions: features[feature].checklist.steps[step].actions || [],
            kb: features[feature].checklist.steps[step].kb || null,
            excerpt: features[feature].checklist.steps[step].excerpt || null,
            priority: features[feature].checklist.steps[step].priority,
            number: 0,
            time: features[feature].checklist.steps[step].time || [0, 0],
            note: features[feature].checklist.steps[step].note || null,
          };

          featureTime[0] += features[feature].checklist.steps[step].time[0];
          featureTime[1] += features[feature].checklist.steps[step].time[1];

          time[0] += features[feature].checklist.steps[step].time[0];
          time[1] += features[feature].checklist.steps[step].time[1];

          if (check.enabled) {
            count.checklist.total++;
            count.feature.total++;
          }

          if (check.completed) {
            count.feature.complete++;
            count.checklist.complete++;
          }
        });

        checklist[feature].progress = {
          total: count.feature.total,
          complete: count.feature.complete,
          remaining: count.feature.total - count.feature.complete,
          percent: Math.floor((count.feature.complete / count.feature.total) * 100),
        };
        checklist[feature].time = featureTime;
      });

      // Nest features
      Object.values(checklist).forEach((feature) => {
        if (checklist[feature.nest]) {
          checklist[feature.nest].steps = { ...checklist[feature.nest].steps, ...feature.steps };

          checklist[feature.nest].progress.total += feature.progress.total;
          checklist[feature.nest].progress.complete += feature.progress.complete;
          checklist[feature.nest].progress.remaining +=
            checklist[feature.nest].progress.total - checklist[feature.nest].progress.complete;
          checklist[feature.nest].progress.percent = Math.floor(
            (checklist[feature.nest].progress.complete / checklist[feature.nest].progress.total) * 100
          );

          checklist[feature.nest].time[0] += feature.time[0];
          checklist[feature.nest].time[1] += feature.time[1];

          delete checklist[feature.slug];
        }
      });

      let progress = {
        total: count.checklist.total,
        complete: count.checklist.complete,
        remaining: count.checklist.total - count.checklist.complete,
        percent: Math.floor((count.checklist.complete / count.checklist.total) * 100),
        checklist: {},
      };

      let numbers = 1;

      Object.keys(checklist).forEach((feature) => {
        Object.entries(checklist[feature].steps)
          .sort((a, b) => a[1].priority - b[1].priority)
          .forEach((step) => {
            checklist[feature].steps[step[0]].number = numbers;
            numbers++;
          });
      });

      // Merging the checklist object into the state
      this.setState((prevState) => ({
        ...prevState,
        checklist: {
          progress: progress,
          time: time,
          features: checklist,
        },
      }));

      resolve(true);
    } catch (error) {
      console.error(error, "~393");
      reject(error, "~217");
    }
  });
}

/**
 * Recalculates the checklist.
 */
export function recalculateChecklist() {
  calculateChecklist.call(this);
}

/**
 * Retrieves the checklist or a specific feature from the state.
 *
 * @param {string|null} feature - The name of the feature to retrieve. If null, the entire checklist will be returned.
 * @returns {Array|Object} - The checklist or the specified feature object.
 */
export function getChecklist(feature = null) {
  try {
    if (feature == null) return this.state.checklist;
    if (feature != null && this.state.checklist.features[feature]) return this.state.checklist.features[feature];
  } catch (error) {
    this.errors.error(this.props.t("unexpectedError"), error, "~239");
    return [];
  }
}

/**
 * Retrieves the progress of a feature or the overall project progress.
 *
 * @param {string} [feature] - The feature for which to retrieve the progress. If not provided, the overall project progress will be returned.
 * @returns {number} - The progress value of the specified feature or the overall project progress.
 */
export function getProgress(feature = null) {
  let testId = unit.report({
    method: "getProgress",
    message: "Testing here. " + feature,
    payload: feature
      ? "Returning progresss for " + feature
      : "No feature was passed as a request. Will Return project progress.",
    test: "The user should see an entire checklist in a popup or dialog window.",
    from: "~252",
  });
  try {
    if (feature == null) {
      return this.state.checklist.progress;
    } else if (feature != null && this.state.checklist.features[feature]) {
      return this.state.checklist.features[feature].progress;
    } else {
      this.errors.error(true, "It looks like there was an error getting progress for this project.", "~260");
      unit.failed({ testId: testId });
      return 0;
    }
  } catch (error) {
    const { errors } = this.props;
    unit.failed({ testId: testId, error: error });
    errors.warn(true, error, "~267");
    return 0;
  }
}

/**
 * Retrieves the flattened checklist.
 * @returns {Array} The flattened checklist.
 */
export function getChecklistFlat() {
  try {
    let tmp = Object.keys(this.state.checklist.features)
      .map((feature) =>
        Object.keys(this.state.checklist.features[feature].steps).map(
          (step) => this.state.checklist.features[feature].steps[step]
        )
      )
      .flat(1);
    return tmp;
  } catch (error) {
    //this.errors.error(true, error, "~319");
    return [];
  }
}

/**
 * Retrieves the time associated with a checklist feature.
 *
 * @param {string|null} feature - The feature to retrieve the time for. If null, the overall checklist time is returned.
 * @returns {Array<number>} - An array representing the time associated with the feature. The first element is the hours and the second element is the minutes.
 */
export function getChecklistTime(feature = null) {
  if (feature == null) return this.state.checklist.time;
  try {
    return this.state.checklist.features[feature].time;
  } catch (e) {
    return [0, 0];
  }
}
