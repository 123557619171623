/** @format */
import Config from "@Workspace/config";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "firemade";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { nanoid } from "nanoid";
import { Functional } from "unit";

const unit = Functional.unit("Project");

let debouncedFn; // Initialize at a higher scope so that it persists across function calls
let backupTimer; // Declare backup timer

/**
 * Sets up the project with the specified version.
 * @param {string} from - The version to set up the project from.
 * @returns {Object} - The selected project.
 * @throws {Error} - If an error occurs during setup.
 */
export default function setupProject(from = "~36") {
  this.id = this.props.router.params.workspaceId;
  try {
    // Initialize the backup timer to run every 10 minutes (600000ms)
    backupTimer = setInterval(() => {
      saveProjectRevision.call(this, nanoid(), from);
    }, 180000);

    // Attempt to save before we leave
    window.addEventListener("beforeunload", () => {
      saveProject.call(this);
    });

    return selectProject.call(this);
  } catch (error) {
    throw error;
  }
}

/**
 * Debounces the saveProject function and calls it with the specified 'from' parameter.
 * @param {string} from - The value to pass as the 'from' parameter when calling saveProject.
 */
export function debouncedSaveProject(from = "~19") {
  // console.log("10. Debounce saved.");
  if (!debouncedFn) {
    debouncedFn = debounce(function (from) {
      // console.log("11. Debounced save project.", "~23");
      saveProject.call(this, from);
    }, 500);
  }
  debouncedFn.call(this, from); // Use the debounced function here
}

/**
 * Returns the project ID.
 * @returns {string} The project ID.
 */
export function getProjectId() {
  return this.id;
}

/**
 * Retrieves the project data from the state.
 * @returns {any} The project data.
 */
export function getProjectData() {
  return this.state.project;
}

/**
 * Retrieves the project token from the state.
 * @returns {string} The project token.
 */
export function getProjectToken() {
  return this.state.project.token;
}

// Function to normalize project by comparing workspace and project features
/**
 * Normalizes a project by extracting features, data, and checklist from the workspace configuration.
 * If a project lacks specific feature data or checklist, it falls back to the workspace defaults.
 * @param {Object} project - The project object to be normalized.
 * @returns {Object} - The normalized project object with up-to-date features.
 */
export function normalizeProject(project) {
  // Extract features of the given project
  const projectFeatures = project.features;

  // Extract data, features and checklist of workspace from the config
  const workspaceData = Config.data;
  const workspaceFeatures = Config.features;
  const workspaceChecklist = Config.checklist;

  // Initialize an empty object to store the normalized project
  let normalizedProject = {};

  // Iterate through all features available in the workspace
  for (const featureKey in workspaceFeatures) {
    // Ensure the featureKey exists in the workspaceFeatures object
    if (workspaceFeatures.hasOwnProperty(featureKey)) {
      // Fetch the data and checklist items for this feature from workspace config
      const featureData = workspaceData[featureKey] || {};
      const featureChecklist = workspaceChecklist[featureKey] || {};

      // Create a reduced checklist initialized with steps set to 'false'
      const reducedChecklist = Object.keys(featureChecklist.steps || {}).reduce((acc, stepKey) => {
        acc[stepKey] = false;
        return acc;
      }, {});

      // Populate normalizedProject with the feature data, setting defaults if the project lacks them
      normalizedProject[featureKey] = {
        // Check if the project has this feature enabled, otherwise use workspace default
        enabled: projectFeatures.hasOwnProperty(featureKey)
          ? projectFeatures[featureKey].enabled
          : workspaceFeatures[featureKey].enabled,

        // If the project has specific data for this feature, use that. Otherwise, use workspace default.
        data:
          projectFeatures.hasOwnProperty(featureKey) && projectFeatures[featureKey].data
            ? projectFeatures[featureKey].data
            : featureData,

        // If the project has a checklist for this feature, use that. Otherwise, use the reduced checklist.
        checklist:
          projectFeatures.hasOwnProperty(featureKey) && projectFeatures[featureKey].checklist
            ? projectFeatures[featureKey].checklist
            : reducedChecklist,

        // Setup the tips
        tips: Array.isArray(projectFeatures[featureKey]?.tips) ? projectFeatures[featureKey].tips : [],
      };
    }
  }

  // Return the normalized project with up-to-date features
  return normalizedProject;
}

/**
 * Selects a project.
 *
 * @param {string} from - The source of the selection.
 * @returns {Promise<boolean>} - A promise that resolves to true if the project is successfully selected.
 */
export function selectProject(from = "~147") {
  let testId = unit.report({
    method: "selectProject",
    steps: ["From home screen", "Select projects", "Use should be moved to the project they selected"],
    test: "User should be able to access all their projects.",
    message: "The project selected should be viewable.",
    action: true,
    analyze: true,
    from: "~155",
    event: "openProject",
  });
  const { router } = this.props;
  return new Promise(async (resolve) => {
    try {
      const unsubscribe = onSnapshot(doc(db, "projects", router.params.workspaceId), (doc) => {
        if (doc.exists()) {
          const projectData = doc.data();
          const normalizedFeatures = normalizeProject(projectData);

          this.setState(
            {
              project: { ...projectData, ...{ id: this.id }, features: normalizedFeatures },
              loaded: true,
            },
            () => {
              resolve(true);
            }
          );
        } else {
          try {
            unsubscribe();
          } catch (_) {}
          // # TODO - This is a fallback error (probably should be removed)
          this.access.open(
            {
              reason: "errorMissingProject",
              component: "MissingProject",
              confirm: () => (document.location.href = "/"),
            },
            from
          );
        }
      });
      return unsubscribe;
    } catch (error) {
      unit.failed({
        testId: testId,
        from: from,
        error: error,
      });
      throw error;
    }
  });
}

/**
 * Saves the project with the provided data.
 * @param {string} [from="~204"] - The source of the save operation.
 * @returns {void}
 */
export function saveProject() {
  // console.log("7. Now saving project.", "~208");
  const { errors, t } = this.props;
  const revisionId = nanoid();
  try {
    // # TODO - basic data validation before saving bad data (this should be expande)
    if (!this.state.project.userId || !this.state.project.token) {
      errors.error(t("errorProjectCorruption"), "Project is missing a user id or token.", "~214");
      return;
    }

    setDoc(doc(db, "projects", this.id), { ...this.state.project, revisionId: revisionId }, { merge: true }).then(
      () => {
        // console.log("8. Saved.", this.state.project, "~220");
        unit.report({
          method: "saveProject",
          event: "saveProject",
        });

        // Recalculate the checklist
        this.checklist.recalculate();
      }
    );
  } catch (error) {
    console.error(error, "~228");
    errors.error(t("errorProjectSave"), error, "~229");
  }
}

/**
 * Saves a project revision.
 *
 * @param {string} revisionId - The ID of the revision.
 * @param {string} [from="~237"] - The source of the revision.
 * @returns {Promise<boolean>} A promise that resolves to true if the revision is saved successfully.
 */
export function saveProjectRevision(revisionId, from = "~240") {
  // console.log("9. Creating revision.", this.state.project, from);
  return new Promise((resolve, reject) => {
    // # TODO - basic data validation before saving bad data (this should be expande)
    if (!this.state.project.userId || !this.state.project.token) {
      errors.error(t("errorProjectCorruption"), "Project is missing a user id or token.", "~245");
      return;
    }

    try {
      // Create a revision with the current project data
      setDoc(doc(db, "projectRevisions", revisionId), {
        ...this.state.project,
        timestamp: { created: Date.now(), updated: Date.now() },
      }).then(() => {
        resolve(true);
      });
    } catch (error) {
      console.error(error, "~258");
      reject(error);
    }
  });
}

/**
 * Restores a project revision with the provided data.
 * @param {Object} data - The data to merge with the project.
 * @param {string} [from="~267"] - The revision ID to restore from.
 * @returns {Promise<boolean>} - A promise that resolves to true if the project is successfully restored, or rejects with an error if there was an issue.
 */
export function restoreProjectRevision(data, from = "~270") {
  return new Promise(async (resolve, reject) => {
    const merged = {
      ...this.state.project,
      ...data,
      revisionId: nanoid(),
      timestamp: {
        created: new Date(),
        updated: new Date(),
      },
    };

    // # TODO - basic data validation before saving bad data (this should be expande)
    if (!merged.userId || !merged.token) {
      errors.error(true, "Project is missing a user id or token.", "~284");
      return;
    }

    try {
      // Create the current project revision
      await saveProjectRevision.call(this, nanoid(), "~290");

      // Create a revision with the current project data
      setDoc(doc(db, "projects", this.id), merged, { merge: true })
        .then(() => {
          // console.log("8. Saved.", this.state.project, "~295");
          unit.report({
            method: "restoredProject",
            event: "restoredProject",
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Updates a project with the provided data.
 *
 * @param {Object} update - The data to update the project with.
 * @param {string} [from="~315"] - The source of the update.
 * @returns {Promise<boolean>} - A promise that resolves to true if the project is updated successfully.
 * @throws {Error} - If there is an error updating the project.
 */
export function updateProject(update, from = "~319") {
  let testId = unit.report({
    method: "updateProject",
    from: from,
    message: "Starting update.",
    test: "Book data should be saved and retained. Check with any and all available features.",
    steps: ["From workspace", "Make change to any feature", "Refresh workspace", "Features should reflect changes"],
    group: "feature",
  });

  return new Promise((resolve, reject) => {
    try {
      let { data = {}, performing = false } = update;

      if (performing) this.props.performing.set.updating("updating", "~333");
      // console.log("5. Updated project has run. The next step is to organize the data.", from);

      // Log some ai stats
      this.ai.log(update);

      const state = cloneDeep(this.state);

      let updated = {
        ...state,
        project: {
          ...state.project,
          ...data,
          features: { ...state.project.features, ...data.features },
        },
      };

      this.setState({ project: updated.project }, () => {
        // console.log("6. The project has been saved from the state.", from);
        try {
          debouncedSaveProject.call(this, from);
        } catch (error) {
          console.error(error, "~355");
        }
        if (performing) this.props.performing.set.updating("success", "~357");
        resolve(true);
      });
    } catch (error) {
      if (performing) this.props.performing.set.updating("error", "~361");
      unit.failed({ testId: testId });
      return reject(error, "~363");
    }
  });
}

/**
 * Updates a feature in the project.
 *
 * @param {Object} update - The update object containing the feature and data to update.
 * @param {string} [from="~372"] - The source of the update.
 * @returns {Promise<boolean>} - A promise that resolves to true if the update is successful.
 * @throws {Error} - If an error occurs during the update process.
 */
export function updateFeature(update, from = "~376") {
  let testId = unit.report({
    method: "updateFeature",
    from: from,
    message: "Starting update.",
    test: "Book data should be saved and retained. Check with any and all available features.",
    steps: ["From workspace", "Make change to any feature", "Refresh workspace", "Features should reflect changes"],
    group: "feature",
  });

  // console.log("1. Updating feature", from);

  return new Promise((resolve, reject) => {
    let { feature = null, data = null, performing = false } = update;
    // console.log("2. Check which features are attempted to update for", feature, "~390");

    // Log some ai stats
    this.ai.log(update);

    try {
      let tmp = cloneDeep(this.state.project.features);
      tmp[feature].data = { ...tmp[feature].data, ...data };
      // console.log("3. After the clone deep, is the data still here?", tmp[feature].data, "~398");

      updateProject
        .call(this, { data: { features: tmp }, performing: performing, note: null, ai: null }, from)
        .then(() => {
          // console.log("4. Updated the project. Now the save should be debounced to a scheduled update.");
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      console.error(error, "~410");
      unit.failed({ testId: testId });
      return reject(error, "~412");
    }
  });
}

/**
 * Updates a step in the project checklist.
 * @param {Object} options - The options for updating the step.
 * @param {string} options.feature - The feature to update the step for.
 * @param {string} options.step - The step to update.
 * @param {string|null} [options.note=null] - The note for the update.
 * @param {boolean} [options.state=true] - The state of the step.
 * @param {string} [from="~424"] - The source of the update.
 * @returns {Promise<boolean>} A promise that resolves to true if the step is updated successfully, or rejects with an error.
 */
export function updateStep({ feature, step, note = null, state = true }, from = "~427") {
  let testId = unit.report(
    {
      method: "setStep",
      payload: { step: step, feature: feature, state: state },
      test: "The step should be updated, progress logged and retained after a page load.",
      steps: ["From workspace", ""],
    },
    from
  );
  return new Promise((resolve, reject) => {
    try {
      let tmp = cloneDeep(this.state.project);

      if (!tmp.features[feature] || !tmp.features[feature].checklist) return false;

      tmp.features[feature].checklist[step] = state;

      updateProject
        .call(this, { data: tmp, performing: true, ai: null, note: note || "Updated step." }, from)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      unit.failed({ testId: testId });
      return reject(error, "~455");
    }
  });
}

/**
 * Teardown the project.
 * Clears the backup timer and removes the event listener for saving the project before unloading the window.
 */
export function teardownProject() {
  // Clear the backup timer when tearing down
  clearInterval(backupTimer);
  window.removeEventListener("beforeunload", saveProject);
}
