/** @format */

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "firemade";

async function upload({
  userId,
  attachedTo = [],
  collections = [],
  files = [],
  tags = [],
  meta = {},
  returns = "id",
  shared = false,
  save = true,
}) {
  if (!Array.isArray(files)) files = [files];
  if (!Array.isArray(collections)) collections = [collections];
  if (!Array.isArray(attachedTo)) attachedTo = [attachedTo];

  let media = this;

  // Set custom metadata, including cache control
  const metadata = {
    cacheControl: "public, max-age=31536000",
  };

  return await new Promise(async (resolve, reject) => {
    try {
      if (files.length == 0) reject("noMediaUploaded");
      await files.forEach(async (file) => {
        const storageRef = ref(storage, file.directory + file.filename);
        uploadBytes(storageRef, file.blob, metadata).then((snapshot) => {
          getDownloadURL(snapshot.ref).then(async (url) => {
            if (!save) return resolve(url);
            let saved = await media.save({
              id: file.id,
              userId: userId || null,
              attachedTo: attachedTo,
              collections: collections,
              tags: tags.filter((n) => n),
              meta: meta,
              url: url,
              type: file.type,
              file: file.filename,
              directory: file.directory,
              shared: shared,
              variations: { clco: url, cluc: url }, // this should be compressed, but it's not
            });
            if (saved) resolve(returns && saved[returns] ? saved[returns] : saved);
          });
        });
      });
    } catch (error) {
      return reject(error, "~63");
    }
  });
}

export { upload };
