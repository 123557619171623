/** @format */
import { Drawer, styled } from "@mui/material";
import { Component as ReactComponent } from "react";

import { withTour } from "tour";
import { withUI } from "ui";
import { withUnit } from "unit";

const StyledDrawer = styled(Drawer)(() => ({
  "&": {
    zIndex: 1600,
    overflowY: "scroll",
  },
  "& > div": {
    width: "33vw",
  },
}));

class Right extends ReactComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { ui } = this.props;
    return (
      <span data-testid="right-drawer">
        <StyledDrawer
          hideBackdrop={true}
          anchor="right"
          open={ui.opened("right")}
          onClose={() => ui.menu.close("right")}
          variant="persistent"
        >
          {this.props.children}
        </StyledDrawer>
      </span>
    );
  }
}

export default withUI(withTour(withUnit(Right)));
