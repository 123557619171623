/** @format */
import { Avatar, Box, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DynamicIcon from "dynamicicon";
import { useLocales } from "locales";
import { useEffect, useState } from "react";
import { useRouter } from "router";
import { useProfiles } from "../../context";
import Name from "../Name";

export default function AvatarComponent({ id, size = 24, name = false, link = false }) {
  const profiles = useProfiles();
  const theme = useTheme();

  const sx = { bgcolor: theme.palette.info.main, width: size + "px", height: size + "px" };

  const { url } = useLocales();

  const { navigate } = useRouter();

  const [ready, setReady] = useState(false);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    const response = profiles.get(id);

    if (response.ready) {
      // Set that this component is ready
      setReady(true);

      // Set the profile
      setProfile(response);
    }
  }, [profiles.users]);

  return (
    <Box
      justifyContent={"center"}
      sx={{ cursor: link ? "pointer" : "auto" }}
      onClick={link ? () => navigate(url("/people/" + id)) : null}
    >
      {!ready ? (
        <Skeleton height={size} width={size} />
      ) : (
        <Avatar color="info" src={profile.photoURL} sx={sx} variant="rounded">
          <DynamicIcon icon="fa-user" size="2xs" />
        </Avatar>
      )}
      {name && (
        <Box sx={{ padding: "4px 0px 0px 0px", fontSize: "10px", minHeight: "13px" }}>
          <Name id={id} />
        </Box>
      )}
    </Box>
  );
}
