/** @format */
import { Component as ReactComponent } from "react";

import { Box } from "@mui/material";

import { withActions } from "actions";
import { withBroadcast } from "broadcast";
import { ComponentError } from "errors";
import { withUI } from "ui";

import RightMenu from "@App/Menus/Right";

class Right extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: "assist",
    };
  }

  render() {
    const { children, workspace } = this.props;
    if (!workspace.ready) return <></>;
    return (
      <RightMenu>
        <ComponentError>
          <Box sx={{ minWidth: "30vw" }}>{children}</Box>
        </ComponentError>
      </RightMenu>
    );
  }
}

export default withBroadcast(withUI(withActions(Right)));
