/** @format */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import camelCase from "camelcase";
import { TinyError } from "errors";
import React, { useEffect, useState } from "react";

const dynamicIconImport = (iconName) => {
  switch (iconName) {
    case "faApple":
      return import("@fortawesome/free-brands-svg-icons/faApple");
    case "faCanadianMapleLeaf":
      return import("@fortawesome/free-brands-svg-icons/faCanadianMapleLeaf");
    case "faCcAmex":
      return import("@fortawesome/free-brands-svg-icons/faCcAmex");
    case "faCcMastercard":
      return import("@fortawesome/free-brands-svg-icons/faCcMastercard");
    case "faCcVisa":
      return import("@fortawesome/free-brands-svg-icons/faCcVisa");
    case "faFacebook":
      return import("@fortawesome/free-brands-svg-icons/faFacebook");
    case "faGoogle":
      return import("@fortawesome/free-brands-svg-icons/faGoogle");
    case "faInstagram":
      return import("@fortawesome/free-brands-svg-icons/faInstagram");
    case "faLinkedin":
      return import("@fortawesome/free-brands-svg-icons/faLinkedin");
    case "faSquareFacebook":
      return import("@fortawesome/free-brands-svg-icons/faSquareFacebook");
    case "faSquareTwitter":
      return import("@fortawesome/free-brands-svg-icons/faSquareTwitter");
    case "faThreads":
      return import("@fortawesome/free-brands-svg-icons/faThreads");
    case "faTwitter":
      return import("@fortawesome/free-brands-svg-icons/faTwitter");
    case "faYoutube":
      return import("@fortawesome/free-brands-svg-icons/faYoutube");
    case "faAlignCenter":
      return import("@fortawesome/pro-solid-svg-icons/faAlignCenter");
    case "faAlignLeft":
      return import("@fortawesome/pro-solid-svg-icons/faAlignLeft");
    case "faAlignRight":
      return import("@fortawesome/pro-solid-svg-icons/faAlignRight");
    case "faArrowDown":
      return import("@fortawesome/pro-solid-svg-icons/faArrowDown");
    case "faSparkles":
      return import("@fortawesome/pro-solid-svg-icons/faSparkles");
    case "faArrowDownArrowUp":
      return import("@fortawesome/pro-solid-svg-icons/faArrowDownArrowUp");
    case "faArrowDownLeftAndArrowUpRightToCenter":
      return import("@fortawesome/pro-solid-svg-icons/faArrowDownLeftAndArrowUpRightToCenter");
    case "faArrowRight":
      return import("@fortawesome/pro-solid-svg-icons/faArrowRight");
    case "faArrowRightArrowLeft":
      return import("@fortawesome/pro-solid-svg-icons/faArrowRightArrowLeft");
    case "faArrowSquareDown":
      return import("@fortawesome/pro-solid-svg-icons/faArrowSquareDown");
    case "faArrowSquareLeft":
      return import("@fortawesome/pro-solid-svg-icons/faArrowSquareLeft");
    case "faArrowSquareRight":
      return import("@fortawesome/pro-solid-svg-icons/faArrowSquareRight");
    case "faArrowSquareUp":
      return import("@fortawesome/pro-solid-svg-icons/faArrowSquareUp");
    case "faArrowUp":
      return import("@fortawesome/pro-solid-svg-icons/faArrowUp");
    case "faArrowsH":
      return import("@fortawesome/pro-solid-svg-icons/faArrowsH");
    case "faArrowsLeftRightToLine":
      return import("@fortawesome/pro-solid-svg-icons/faArrowsLeftRightToLine");
    case "faArrowsRotate":
      return import("@fortawesome/pro-solid-svg-icons/faArrowsRotate");
    case "faArrowsToCircle":
      return import("@fortawesome/pro-solid-svg-icons/faArrowsToCircle");
    case "faArrowsUpDownLeftRight":
      return import("@fortawesome/pro-solid-svg-icons/faArrowsUpDownLeftRight");
    case "faAt":
      return import("@fortawesome/pro-solid-svg-icons/faAt");
    case "faBarcodeRead":
      return import("@fortawesome/pro-solid-svg-icons/faBarcodeRead");
    case "faBarcodeScan":
      return import("@fortawesome/pro-solid-svg-icons/faBarcodeScan");
    case "faBars":
      return import("@fortawesome/pro-solid-svg-icons/faBars");
    case "faBarsProgress":
      return import("@fortawesome/pro-solid-svg-icons/faBarsProgress");
    case "faBell":
      return import("@fortawesome/pro-solid-svg-icons/faBell");
    case "faBenchTree":
      return import("@fortawesome/pro-solid-svg-icons/faBenchTree");
    case "faBinoculars":
      return import("@fortawesome/pro-solid-svg-icons/faBinoculars");
    case "faBlog":
      return import("@fortawesome/pro-solid-svg-icons/faBlog");
    case "faBold":
      return import("@fortawesome/pro-solid-svg-icons/faBold");
    case "faBomb":
      return import("@fortawesome/pro-solid-svg-icons/faBomb");
    case "faBook":
      return import("@fortawesome/pro-solid-svg-icons/faBook");
    case "faBookCopy":
      return import("@fortawesome/pro-solid-svg-icons/faBookCopy");
    case "faBookOpen":
      return import("@fortawesome/pro-solid-svg-icons/faBookOpen");
    case "faBookOpenCover":
      return import("@fortawesome/pro-solid-svg-icons/faBookOpenCover");
    case "faBookOpenReader":
      return import("@fortawesome/pro-solid-svg-icons/faBookOpenReader");
    case "faBookmark":
      return import("@fortawesome/pro-solid-svg-icons/faBookmark");
    case "faBorderCenterV":
      return import("@fortawesome/pro-solid-svg-icons/faBorderCenterV");
    case "faBoxOpenFull":
      return import("@fortawesome/pro-solid-svg-icons/faBoxOpenFull");
    case "faBrain":
      return import("@fortawesome/pro-solid-svg-icons/faBrain");
    case "faBriefcase":
      return import("@fortawesome/pro-solid-svg-icons/faBriefcase");
    case "faBullseye":
      return import("@fortawesome/pro-solid-svg-icons/faBullseye");
    case "faCalculator":
      return import("@fortawesome/pro-solid-svg-icons/faCalculator");
    case "faCalendarAlt":
      return import("@fortawesome/pro-solid-svg-icons/faCalendarAlt");
    case "faCameraViewfinder":
      return import("@fortawesome/pro-solid-svg-icons/faCameraViewfinder");
    case "faCameraWeb":
      return import("@fortawesome/pro-solid-svg-icons/faCameraWeb");
    case "faCaretDown":
      return import("@fortawesome/pro-solid-svg-icons/faCaretDown");
    case "faChampagneGlass":
      return import("@fortawesome/pro-solid-svg-icons/faChampagneGlass");
    case "faChartNetwork":
      return import("@fortawesome/pro-solid-svg-icons/faChartNetwork");
    case "faCheck":
      return import("@fortawesome/pro-solid-svg-icons/faCheck");
    case "faCheckSquare":
      return import("@fortawesome/pro-solid-svg-icons/faCheckSquare");
    case "faChevronDoubleLeft":
      return import("@fortawesome/pro-solid-svg-icons/faChevronDoubleLeft");
    case "faChevronDoubleRight":
      return import("@fortawesome/pro-solid-svg-icons/faChevronDoubleRight");
    case "faChevronDown":
      return import("@fortawesome/pro-solid-svg-icons/faChevronDown");
    case "faChevronLeft":
      return import("@fortawesome/pro-solid-svg-icons/faChevronLeft");
    case "faChevronRight":
      return import("@fortawesome/pro-solid-svg-icons/faChevronRight");
    case "faChevronUp":
      return import("@fortawesome/pro-solid-svg-icons/faChevronUp");
    case "faCircleExclamation":
      return import("@fortawesome/pro-solid-svg-icons/faCircleExclamation");
    case "faCircleInfo":
      return import("@fortawesome/pro-solid-svg-icons/faCircleInfo");
    case "faCirclePlus":
      return import("@fortawesome/pro-solid-svg-icons/faCirclePlus");
    case "faCircleTrash":
      return import("@fortawesome/pro-solid-svg-icons/faCircleTrash");
    case "faClapperboard":
      return import("@fortawesome/pro-solid-svg-icons/faClapperboard");
    case "faClock":
      return import("@fortawesome/pro-solid-svg-icons/faClock");
    case "faCloudWord":
      return import("@fortawesome/pro-solid-svg-icons/faCloudWord");
    case "faCodeBranch":
      return import("@fortawesome/pro-solid-svg-icons/faCodeBranch");
    case "faCodeMerge":
      return import("@fortawesome/pro-solid-svg-icons/faCodeMerge");
    case "faCog":
      return import("@fortawesome/pro-solid-svg-icons/faCog");
    case "faCoin":
      return import("@fortawesome/pro-solid-svg-icons/faCoin");
    case "faCommentAltLines":
      return import("@fortawesome/pro-solid-svg-icons/faCommentAltLines");
    case "faCommentQuestion":
      return import("@fortawesome/pro-solid-svg-icons/faCommentQuestion");
    case "faComments":
      return import("@fortawesome/pro-solid-svg-icons/faComments");
    case "faCompass":
      return import("@fortawesome/pro-solid-svg-icons/faCompass");
    case "faCompassDrafting":
      return import("@fortawesome/pro-solid-svg-icons/faCompassDrafting");
    case "faCopy":
      return import("@fortawesome/pro-solid-svg-icons/faCopy");
    case "faCopyright":
      return import("@fortawesome/pro-solid-svg-icons/faCopyright");
    case "faCreditCard":
      return import("@fortawesome/pro-solid-svg-icons/faCreditCard");
    case "faCrop":
      return import("@fortawesome/pro-solid-svg-icons/faCrop");
    case "faCrown":
      return import("@fortawesome/pro-solid-svg-icons/faCrown");
    case "faDesktop":
      return import("@fortawesome/pro-solid-svg-icons/faDesktop");
    case "faDollarSign":
      return import("@fortawesome/pro-solid-svg-icons/faDollarSign");
    case "faDoorOpen":
      return import("@fortawesome/pro-solid-svg-icons/faDoorOpen");
    case "faDown":
      return import("@fortawesome/pro-solid-svg-icons/faDown");
    case "faEdit":
      return import("@fortawesome/pro-solid-svg-icons/faEdit");
    case "faEllipsis":
      return import("@fortawesome/pro-solid-svg-icons/faEllipsis");
    case "faEnvelope":
      return import("@fortawesome/pro-solid-svg-icons/faEnvelope");
    case "faExchangeAlt":
      return import("@fortawesome/pro-solid-svg-icons/faExchangeAlt");
    case "faExpand":
      return import("@fortawesome/pro-solid-svg-icons/faExpand");
    case "faExpandAlt":
      return import("@fortawesome/pro-solid-svg-icons/faExpandAlt");
    case "faEye":
      return import("@fortawesome/pro-solid-svg-icons/faEye");
    case "faEyeSlash":
      return import("@fortawesome/pro-solid-svg-icons/faEyeSlash");
    case "faFaceConfused":
      return import("@fortawesome/pro-solid-svg-icons/faFaceConfused");
    case "faFileDashedLine":
      return import("@fortawesome/pro-solid-svg-icons/faFileDashedLine");
    case "faFiles":
      return import("@fortawesome/pro-solid-svg-icons/faFiles");
    case "faFill":
      return import("@fortawesome/pro-solid-svg-icons/faFill");
    case "faFlag":
      return import("@fortawesome/pro-solid-svg-icons/faFlag");
    case "faFlagUsa":
      return import("@fortawesome/pro-solid-svg-icons/faFlagUsa");
    case "faFont":
      return import("@fortawesome/pro-solid-svg-icons/faFont");
    case "faGameBoardSimple":
      return import("@fortawesome/pro-solid-svg-icons/faGameBoardSimple");
    case "faGear":
      return import("@fortawesome/pro-solid-svg-icons/faGear");
    case "faGlasses":
      return import("@fortawesome/pro-solid-svg-icons/faGlasses");
    case "faGlobe":
      return import("@fortawesome/pro-solid-svg-icons/faGlobe");
    case "faGoalNet":
      return import("@fortawesome/pro-solid-svg-icons/faGoalNet");
    case "faGraduationCap":
      return import("@fortawesome/pro-solid-svg-icons/faGraduationCap");
    case "faGrid2":
      return import("@fortawesome/pro-solid-svg-icons/faGrid2");
    case "faH1":
      return import("@fortawesome/pro-solid-svg-icons/faH1");
    case "faHandLizard":
      return import("@fortawesome/pro-solid-svg-icons/faHandLizard");
    case "faHandPointer":
      return import("@fortawesome/pro-solid-svg-icons/faHandPointer");
    case "faHandshake":
      return import("@fortawesome/pro-solid-svg-icons/faHandshake");
    case "faHashtag":
      return import("@fortawesome/pro-solid-svg-icons/faHashtag");
    case "faHeadset":
      return import("@fortawesome/pro-solid-svg-icons/faHeadset");
    case "faHeartCrack":
      return import("@fortawesome/pro-solid-svg-icons/faHeartCrack");
    case "faHistory":
      return import("@fortawesome/pro-solid-svg-icons/faHistory");
    case "faHouse":
      return import("@fortawesome/pro-solid-svg-icons/faHouse");
    case "faHundredPoints":
      return import("@fortawesome/pro-solid-svg-icons/faHundredPoints");
    case "faIdCardClip":
      return import("@fortawesome/pro-solid-svg-icons/faIdCardClip");
    case "faImage":
      return import("@fortawesome/pro-solid-svg-icons/faImage");
    case "faImages":
      return import("@fortawesome/pro-solid-svg-icons/faImages");
    case "faInfo":
      return import("@fortawesome/pro-solid-svg-icons/faInfo");
    case "faInputText":
      return import("@fortawesome/pro-solid-svg-icons/faInputText");
    case "faItalic":
      return import("@fortawesome/pro-solid-svg-icons/faItalic");
    case "faLanguage":
      return import("@fortawesome/pro-solid-svg-icons/faLanguage");
    case "faLayerGroup":
      return import("@fortawesome/pro-solid-svg-icons/faLayerGroup");
    case "faLifeRing":
      return import("@fortawesome/pro-solid-svg-icons/faLifeRing");
    case "faLightbulb":
      return import("@fortawesome/pro-solid-svg-icons/faLightbulb");
    case "faLink":
      return import("@fortawesome/pro-solid-svg-icons/faLink");
    case "faListTimeline":
      return import("@fortawesome/pro-solid-svg-icons/faListTimeline");
    case "faLock":
      return import("@fortawesome/pro-solid-svg-icons/faLock");
    case "faLockOpen":
      return import("@fortawesome/pro-solid-svg-icons/faLockOpen");
    case "faMagnifyingGlass":
      return import("@fortawesome/pro-solid-svg-icons/faMagnifyingGlass");
    case "faMap":
      return import("@fortawesome/pro-solid-svg-icons/faMap");
    case "faMarker":
      return import("@fortawesome/pro-solid-svg-icons/faMarker");
    case "faMegaphone":
      return import("@fortawesome/pro-solid-svg-icons/faMegaphone");
    case "faMessageDots":
      return import("@fortawesome/pro-solid-svg-icons/faMessageDots");
    case "faMessageLines":
      return import("@fortawesome/pro-solid-svg-icons/faMessageLines");
    case "faMessagePen":
      return import("@fortawesome/pro-solid-svg-icons/faMessagePen");
    case "faMessageQuestion":
      return import("@fortawesome/pro-solid-svg-icons/faMessageQuestion");
    case "faMicrochipAi":
      return import("@fortawesome/pro-solid-svg-icons/faMicrochipAi");
    case "faMoneyBill":
      return import("@fortawesome/pro-solid-svg-icons/faMoneyBill");
    case "faMoon":
      return import("@fortawesome/pro-solid-svg-icons/faMoon");
    case "faMousePointer":
      return import("@fortawesome/pro-solid-svg-icons/faMousePointer");
    case "faNoteSticky":
      return import("@fortawesome/pro-solid-svg-icons/faNoteSticky");
    case "faNotes":
      return import("@fortawesome/pro-solid-svg-icons/faNotes");
    case "faObjectGroup":
      return import("@fortawesome/pro-solid-svg-icons/faObjectGroup");
    case "faPaintBrush":
      return import("@fortawesome/pro-solid-svg-icons/faPaintBrush");
    case "faPaintbrushPencil":
      return import("@fortawesome/pro-solid-svg-icons/faPaintbrushPencil");
    case "faPalette":
      return import("@fortawesome/pro-solid-svg-icons/faPalette");
    case "faPaperPlane":
      return import("@fortawesome/pro-solid-svg-icons/faPaperPlane");
    case "faPaperclip":
      return import("@fortawesome/pro-solid-svg-icons/faPaperclip");
    case "faPaste":
      return import("@fortawesome/pro-solid-svg-icons/faPaste");
    case "faPenNib":
      return import("@fortawesome/pro-solid-svg-icons/faPenNib");
    case "faPenToSquare":
      return import("@fortawesome/pro-solid-svg-icons/faPenToSquare");
    case "faPencil": // Check for correct icon name, may be duplicated with faPenNib or faPenToSquare
      return import("@fortawesome/pro-solid-svg-icons/faPencil");
    case "faPeople":
      return import("@fortawesome/pro-solid-svg-icons/faPeople");
    case "faPeopleGroup":
      return import("@fortawesome/pro-solid-svg-icons/faPeopleGroup");
    case "faPersonChalkboard":
      return import("@fortawesome/pro-solid-svg-icons/faPersonChalkboard");
    case "faPersonFalling":
      return import("@fortawesome/pro-solid-svg-icons/faPersonFalling");
    case "faPhone":
      return import("@fortawesome/pro-solid-svg-icons/faPhone");
    case "faPhotoFilm":
      return import("@fortawesome/pro-solid-svg-icons/faPhotoFilm");
    case "faPhotoFilmMusic":
      return import("@fortawesome/pro-solid-svg-icons/faPhotoFilmMusic");
    case "faPipe":
      return import("@fortawesome/pro-solid-svg-icons/faPipe");
    case "faPlay":
      return import("@fortawesome/pro-solid-svg-icons/faPlay");
    case "faPlus":
      return import("@fortawesome/pro-solid-svg-icons/faPlus");
    case "faPrint":
      return import("@fortawesome/pro-solid-svg-icons/faPrint");
    case "faProjector":
      return import("@fortawesome/pro-solid-svg-icons/faProjector");
    case "faQrcode":
      return import("@fortawesome/pro-solid-svg-icons/faQrcode");
    case "faQuestion":
      return import("@fortawesome/pro-solid-svg-icons/faQuestion");
    case "faQuestionCircle":
      return import("@fortawesome/pro-solid-svg-icons/faQuestionCircle");
    case "faQuoteRight":
      return import("@fortawesome/pro-solid-svg-icons/faQuoteRight");
    case "faRabbitRunning":
      return import("@fortawesome/pro-solid-svg-icons/faRabbitRunning");
    case "faReceipt":
      return import("@fortawesome/pro-solid-svg-icons/faReceipt");
    case "faRectangleBarcode":
      return import("@fortawesome/pro-solid-svg-icons/faRectangleBarcode");
    case "faRectangleHistory":
      return import("@fortawesome/pro-solid-svg-icons/faRectangleHistory");
    case "faRedo":
      return import("@fortawesome/pro-solid-svg-icons/faRedo");
    case "faRegistered":
      return import("@fortawesome/pro-solid-svg-icons/faRegistered");
    case "faRepeat":
      return import("@fortawesome/pro-solid-svg-icons/faRepeat");
    case "faRightToBracket":
      return import("@fortawesome/pro-solid-svg-icons/faRightToBracket");
    case "faRobot":
      return import("@fortawesome/pro-solid-svg-icons/faRobot");
    case "faRollerCoaster":
      return import("@fortawesome/pro-solid-svg-icons/faRollerCoaster");
    case "faRulerCombined":
      return import("@fortawesome/pro-solid-svg-icons/faRulerCombined");
    case "faScannerTouchscreen":
      return import("@fortawesome/pro-solid-svg-icons/faScannerTouchscreen");
    case "faScribble":
      return import("@fortawesome/pro-solid-svg-icons/faScribble");
    case "faSearchMinus":
      return import("@fortawesome/pro-solid-svg-icons/faSearchMinus");
    case "faSearchPlus":
      return import("@fortawesome/pro-solid-svg-icons/faSearchPlus");
    case "faShapes":
      return import("@fortawesome/pro-solid-svg-icons/faShapes");
    case "faShare":
      return import("@fortawesome/pro-solid-svg-icons/faShare");
    case "faShareSquare":
      return import("@fortawesome/pro-solid-svg-icons/faShareSquare");
    case "faShieldAlt":
      return import("@fortawesome/pro-solid-svg-icons/faShieldAlt");
    case "faShieldCheck":
      return import("@fortawesome/pro-solid-svg-icons/faShieldCheck");
    case "faShoePrints":
      return import("@fortawesome/pro-solid-svg-icons/faShoePrints");
    case "faSignOutAlt":
      return import("@fortawesome/pro-solid-svg-icons/faSignOutAlt");
    case "faSignalStream":
      return import("@fortawesome/pro-solid-svg-icons/faSignalStream");
    case "faSignature":
      return import("@fortawesome/pro-solid-svg-icons/faSignature");
    case "faSignsPost":
      return import("@fortawesome/pro-solid-svg-icons/faSignsPost");
    case "faSlidersHSquare":
      return import("@fortawesome/pro-solid-svg-icons/faSlidersHSquare");
    case "faSparkle":
      return import("@fortawesome/pro-solid-svg-icons/faSparkle");
    case "faSquareRight":
      return import("@fortawesome/pro-solid-svg-icons/faSquareRight");
    case "faStar":
      return import("@fortawesome/pro-solid-svg-icons/faStar");
    case "faStars":
      return import("@fortawesome/pro-solid-svg-icons/faStars");
    case "faStickyNote":
      return import("@fortawesome/pro-solid-svg-icons/faStickyNote");
    case "faSwatchbook":
      return import("@fortawesome/pro-solid-svg-icons/faSwatchbook");
    case "faTableLayout":
      return import("@fortawesome/pro-solid-svg-icons/faTableLayout");
    case "faTabletButton":
      return import("@fortawesome/pro-solid-svg-icons/faTabletButton");
    case "faTag":
      return import("@fortawesome/pro-solid-svg-icons/faTag");
    case "faTags":
      return import("@fortawesome/pro-solid-svg-icons/faTags");
    case "faTeddyBear":
      return import("@fortawesome/pro-solid-svg-icons/faTeddyBear");
    case "faText":
      return import("@fortawesome/pro-solid-svg-icons/faText");
    case "faThumbsDown":
      return import("@fortawesome/pro-solid-svg-icons/faThumbsDown");
    case "faThumbsUp":
      return import("@fortawesome/pro-solid-svg-icons/faThumbsUp");
    case "faTicket":
      return import("@fortawesome/pro-solid-svg-icons/faTicket");
    case "faTimes":
      return import("@fortawesome/pro-solid-svg-icons/faTimes");
    case "faTimesCircle":
      return import("@fortawesome/pro-solid-svg-icons/faTimesCircle");
    case "faToolbox":
      return import("@fortawesome/pro-solid-svg-icons/faToolbox");
    case "faTrainTrack":
      return import("@fortawesome/pro-solid-svg-icons/faTrainTrack");
    case "faTrash":
      return import("@fortawesome/pro-solid-svg-icons/faTrash");
    case "faTrees":
      return import("@fortawesome/pro-solid-svg-icons/faTrees");
    case "faTriangleExclamation":
      return import("@fortawesome/pro-solid-svg-icons/faTriangleExclamation");
    case "faTvMusic":
      return import("@fortawesome/pro-solid-svg-icons/faTvMusic");
    case "faTypewriter":
      return import("@fortawesome/pro-solid-svg-icons/faTypewriter");
    case "faUnderline":
      return import("@fortawesome/pro-solid-svg-icons/faUnderline");
    case "faUndo":
      return import("@fortawesome/pro-solid-svg-icons/faUndo");
    case "faUnlink":
      return import("@fortawesome/pro-solid-svg-icons/faUnlink");
    case "faUpload":
      return import("@fortawesome/pro-solid-svg-icons/faUpload");
    case "faUser":
      return import("@fortawesome/pro-solid-svg-icons/faUser");
    case "faUserCheck":
      return import("@fortawesome/pro-solid-svg-icons/faUserCheck");
    case "faUserCircle":
      return import("@fortawesome/pro-solid-svg-icons/faUserCircle");
    case "faUserPilot":
      return import("@fortawesome/pro-solid-svg-icons/faUserPilot");
    case "faUserSecret":
      return import("@fortawesome/pro-solid-svg-icons/faUserSecret");
    case "faUsersRays":
      return import("@fortawesome/pro-solid-svg-icons/faUsersRays");
    case "faVideo":
      return import("@fortawesome/pro-solid-svg-icons/faVideo");
    case "faVolcano":
      return import("@fortawesome/pro-solid-svg-icons/faVolcano");
    case "faWandMagicSparkles":
      return import("@fortawesome/pro-solid-svg-icons/faWandMagicSparkles");
    case "faWaterArrowDown":
      return import("@fortawesome/pro-solid-svg-icons/faWaterArrowDown");
    case "faWaveform":
      return import("@fortawesome/pro-solid-svg-icons/faWaveform");
    case "faWindowMaximize":
      return import("@fortawesome/pro-solid-svg-icons/faWindowMaximize");
    case "faWindowMinimize":
      return import("@fortawesome/pro-solid-svg-icons/faWindowMinimize");
    case "faListCheck":
      return import("@fortawesome/pro-solid-svg-icons/faListCheck");
    case "faWrench":
      return import("@fortawesome/pro-solid-svg-icons/faWrench");
    case "faBookUser":
      return import("@fortawesome/pro-solid-svg-icons/faBookUser");
    case "faBookSparkles":
      return import("@fortawesome/pro-solid-svg-icons/faBookSparkles");
    // Fallback
    default:
      return import("@fortawesome/pro-solid-svg-icons/faStar");
  }
};

export default function DynamicIcon({ icon: iconName, props }) {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    if (!iconName || typeof iconName !== "string") {
      console.error("Icon error. Expected string, got", iconName);
      return;
    }

    let normalizedIconName = camelCase(iconName);

    dynamicIconImport(normalizedIconName)
      .then((module) => {
        setIcon(module.definition);
      })
      .catch((error) => console.warn("Failed to load icon:", normalizedIconName, error));
  }, [iconName]);

  if (!icon) return null;

  return (
    <TinyError>
      <FontAwesomeIcon {...props} icon={icon} />
    </TinyError>
  );
}
