/** @format */

import { Functional } from "unit";

const unit = Functional.unit("workspace/step");

/**
 * Executes actions related to certain steps.
 *
 * @param {Array} step - The array of steps.
 * @returns {void}
 */
export function stepActions(step) {
  unit.report({
    method: "stepActions",
    test: "Actions related to certain steps should execute as expected.",
  });

  const { action, ui, errors, t } = this.props;
  const workspace = this;

  try {
    step.forEach((a) => {
      eval(a, "~17");
    });
  } catch (error) {
    errors.warning(t("unexpectedError"), error, "~20");
  }
}

/**
 * Retrieves the step from the checklist of a specific feature.
 *
 * @param {string} feature - The name of the feature.
 * @param {string} step - The step to retrieve from the checklist.
 * @returns {boolean|object} - The step object if it exists, otherwise false.
 */
export function getStep(feature, step) {
  if (!this?.state?.project?.features?.[feature]?.checklist[step]) return false;

  return this.state.project.features[feature].checklist[step];
}

/**
 * Retrieves the steps for a given feature.
 *
 * @param {string} feature - The feature to retrieve steps for.
 * @returns {Array} - An array of steps for the given feature.
 */
export function getSteps(feature) {
  try {
    let tmp = Object.keys(this.state.checklist.features[feature].steps).map((step) => ({
      ...this.state.checklist.features[feature].steps[step],
      ...{ feature: feature },
    }));
    return tmp;
  } catch (error) {
    const { errors } = this.props;
    errors.error(true, error, "~292");
    return [];
  }
}

/**
 * Sets the step for a given feature in the project checklist.
 *
 * @param {Object} options - The options for setting the step.
 * @param {string} options.feature - The feature to set the step for.
 * @param {string} options.step - The step to set.
 * @param {string|null} [options.note=null] - The note for the step.
 * @param {boolean} [options.state=true] - The state of the step.
 * @param {boolean} [options.performing=false] - Indicates if the step is being performed.
 * @returns {Promise<boolean>} - A promise that resolves to true if the step was set successfully, false otherwise.
 */
export async function setStep({ feature, step, note = null, state = true, performing = false }) {
  const {
    performing: { set },
  } = this.props;

  set.updating("updating");

  let project = { ...this.state.project };

  if (!project.features[feature]) return false;

  project.features[feature].checklist[step] = state;

  await this.update
    .project({
      project: project,
      performing: performing,
      from: "~53",
      note: note || "Updated " + feature + " checklist.",
      ai: null,
    })
    .then(() => {
      setTimeout(() => set.updating("success"), 1000);
    })
    .catch(() => {
      set.updating("error");
    });
}

/**
 * Calculates the completion status of a step based on the provided validation criteria.
 *
 * @param {boolean|string} validate - The validation criteria to determine the completion status.
 * @param {string} [language=this.state.project.languages[0]] - The language to use for validation.
 * @returns {boolean} - The completion status of the step.
 * @throws {Error} - If there is an error calculating the completion status.
 */
export function getStepCompleted(validate, language = this.state.project.languages[0]) {
  const { t, errors } = this.props;
  try {
    var criteria = [];
    if (typeof validate != "boolean") {
      if (validate.includes("{language}")) validate = validate.replace(".{language}.", "['" + language + "'].");
      var AO = "";
      if (typeof validate == "string" && validate.includes("||")) {
        AO = "or";
        criteria = validate.split("||");
      } else if (typeof validate == "string" && validate.includes("&&")) {
        AO = "and";
        criteria = validate.split("&&");
      } else {
        AO = null;
        criteria = [validate];
      }
      var co = "";
      var [ls, rs] = [null, null];
      let responses = criteria.map((criterium) => {
        if (criterium.includes("includes")) {
          co = "IN";
          [ls, rs] = [criterium, "true"];
        } else if (criterium.includes("==")) {
          co = "EQ";
          [ls, rs] = criterium.split("==");
        } else if (criterium.includes("!=")) {
          co = "NE";
          [ls, rs] = criterium.split("!=");
        } else if (criterium.includes(">")) {
          co = "GT";
          [ls, rs] = criterium.split(">");
        } else if (criterium.includes("<")) {
          co = "LT";
          [ls, rs] = criterium.split("<");
        }
        if (rs == "true") rs = true;
        if (rs == "false") rs = false;
        if (rs == '""') rs = "";
        try {
          let tls = eval("this.state.project." + ls, "~381");
          let result = false;
          if (co == "IN") result = tls === true;
          if (co == "EQ") result = tls === rs;
          if (co == "NE") result = tls !== rs;
          if (co == "GT") result = tls > rs;
          if (co == "LT") result = tls < rs;
          return result;
          // Doesn't exist
        } catch (error) {
          console.error("Attmped to evaluate", "this.state.project." + ls, "~391");
          return false;
        }
      });
      if (AO == null || AO == "or") {
        return responses.includes(true);
      } else {
        return !responses.includes(false);
      }
    } else {
      return validate;
    }
  } catch (error) {
    errors.error(t("errorCalculatingChecklist"), error, "~407");
  }
}
