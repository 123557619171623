/** @format */
import { Box, Button, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withWorkspace } from "@Workspace/Context";
import { ComponentError, TinyError } from "errors";
import { withLocales } from "locales";
import React, { Component } from "react";
import { withTour } from "tour";
import { withUI } from "ui";
import { withUnit } from "unit";
import Feature from "./components/Feature";
import Limited from "./components/Limited";

import { Panels, Tab, Tabs } from "ui";

const StyledBox = styled(Box)(() => ({
  "&": { maxHeight: "100%" },
}));

class Checklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show || "all",
      limit: props.limit || 999,
    };

    this.unit = this.props.unit.new(this.constructor.name);
  }

  enabledFeatures = () => {
    try {
      const { workspace } = this.props;
      if (!workspace.checklist.get) return [];
      return Object.entries(workspace.checklist.get().features)
        .filter(([_, value]) => value.enabled)
        .map(([key]) => key);
    } catch (e) {
      return [];
    }
  };

  render() {
    const { workspace, t } = this.props;
    const { show, limit } = this.state;
    const enabledFeatures = this.enabledFeatures();

    return (
      <StyledBox>
        {show === "all" ? (
          <ComponentError>
            <TinyError>
              <Tabs slug="checklist/features">
                {enabledFeatures.map((f, i) => {
                  const feature = workspace.feature.get(f);
                  return (
                    <Tab
                      slug="checklist/features"
                      variant="button"
                      size="small"
                      key={`feature-tab-${feature.slug}-${i}`}
                      icon={feature.icon}
                      label={t(feature.title)}
                      id={`feature-tab-${i}`}
                      aria-controls={`feature-tabpanel-${i}`}
                      index={i}
                    />
                  );
                })}
              </Tabs>
              <Box sx={{ margin: "10px 10px 40px 10px", opacity: 0.5 }}>
                <Divider />
              </Box>
            </TinyError>
            <Panels slug="checklist/features">
              {enabledFeatures.map((feature, i) => (
                <TinyError key={`feature-${feature}-${i}`}>
                  <Feature {...this.props} i={i} feature={workspace.checklist.get(feature)} />
                </TinyError>
              ))}
            </Panels>
          </ComponentError>
        ) : (
          <>
            <TinyError>
              <Limited {...this.props} limit={limit} />
            </TinyError>
            {show !== "all" && (
              <>
                <Divider />
                <Box sx={{ padding: "5%", textAlign: "right" }}>
                  <Button variant="contained" color="success" onClick={() => this.setState({ show: "all" })}>
                    {t("showAll")}
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </StyledBox>
    );
  }
}

export default withLocales(withUnit(withWorkspace(withTour(withUI(withLocales(Checklist))))));
