/** @format */
import SpreadTemplate from "@Workspace/Usable/Features/Designer/config/templates/Spread";
import TextTemplate from "@Workspace/Usable/Features/Designer/config/templates/Text";
import Units from "@Workspace/Usable/Features/Designer/config/units.json";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { nanoid } from "nanoid";

// convert mm to px
const convert = (px) => {
  return Number(((px * Units.metric.calc) / 10).toFixed(4));
};

export function reviewTransfer() {
  if (!this.editor.loaded()) return {};

  const draft = cloneDeep(this.props.data.draft);
  const spreadbreak = nanoid();

  let response = { delimiter: spreadbreak, lines: 0, breaks: 0, spreads: 1, words: 0, expected: 0, node: [] };

  // Helper function to count words accurately
  const countWords = (text) => {
    const words = text.trim().split(/\s+/);
    return words.filter((word) => word.length > 0).length;
  };

  // Main function to process nodes
  const extractContent = (node) => {
    let content = [];

    // Use an iterative approach instead of recursion to avoid maximum call stack size exceeded error
    const stack = Array.isArray(node) ? [...node] : [node];

    while (stack.length > 0) {
      const currentNode = stack.shift();

      if (Array.isArray(currentNode)) {
        // If it's an array, add its elements to the stack
        stack.push(...currentNode);
      } else if (currentNode.type === "paragraph") {
        // Process paragraph nodes
        const text = currentNode.children
          .map((child) => child.text)
          .join(" ")
          .trim();
        if (text.length > 0) {
          content.push(text);
          response.lines++;
          response.words += countWords(text);
        }
      } else if (currentNode.type === "break") {
        // Process break nodes
        content.push(spreadbreak);
        response.breaks++;
        response.spreads++;
      }

      // Add children to the stack if they exist
      if (currentNode.children && !Array.isArray(currentNode)) {
        stack.push(currentNode.children);
      }
    }

    return content;
  };

  const content = extractContent(draft);

  return { ...response, nodes: content };
}

function calculateTextHeight(text, style, fontFamily, fontSize) {
  return new Promise((resolve) => {
    const container = document.createElement("div");
    container.id = `temp-${nanoid()}`;
    container.style.position = "absolute";
    container.style.visibility = "hidden";
    container.style.left = "-9999px";
    container.style.top = "-9999px";

    const textElement = document.createElement("div");
    textElement.style.fontFamily = fontFamily;
    textElement.style.fontSize = `${fontSize}px`;
    textElement.style.width = `${style.width}px`;
    textElement.style.lineHeight = style.lineHeight || "normal";
    textElement.style.whiteSpace = "pre-wrap";
    textElement.textContent = text;

    container.appendChild(textElement);
    document.body.appendChild(container);

    requestAnimationFrame(() => {
      const height = textElement.offsetHeight;
      document.body.removeChild(container);
      resolve(height);
    });
  });
}

export function completeTransfer(spreads, scale) {
  const { performing, errors, t, workspace } = this.props;

  return new Promise(async (resolve, reject) => {
    try {
      performing.set.updating("updating", "~110");

      if (!this.editor.loaded) throw "Editors not loaded.";

      if (spreads.length < 4) throw "Not enough spreads are being transferred.";

      const language = workspace.language.get();
      const storyboard = workspace.data.feature("storyboard");
      const newSpreads = [];

      const processNode = async (node) => {
        const clonedTextTemplate = cloneDeep(TextTemplate);
        const scaledWidth = convert(node.width / scale);
        const scaledFontSize = 24 * scale;

        // Calculate the height of the text node
        const calculatedHeight = await calculateTextHeight(
          node.text,
          { ...clonedTextTemplate.style[0], width: scaledWidth },
          storyboard.fonts[0] || "Acme",
          scaledFontSize
        );

        console.log(node.left, "~152");

        return {
          ...clonedTextTemplate,
          id: nanoid(),
          style: [
            {
              ...clonedTextTemplate.style[0],
              width: convert(node.width / scale),
              height: convert(calculatedHeight),
              left: convert(node.left / scale),
              top: convert(node.top / scale),
            },
          ],
          font: { ...clonedTextTemplate.font, size: 24, family: storyboard.fonts[0] || "Acme" },
          text: { [language]: node.text },
        };
      };

      const processSpread = async (spread) => {
        if (spread.length == 0) {
          return cloneDeep(SpreadTemplate);
        } else {
          const newSpread = cloneDeep(SpreadTemplate);
          const processedNodes = await Promise.all(spread.map(processNode));
          newSpread.elements.push(...processedNodes);
          return newSpread;
        }
      };

      const chunkSize = 10;
      for (let i = 0; i < spreads.length; i += chunkSize) {
        const chunk = spreads.slice(i, i + chunkSize);
        const processedChunk = await Promise.all(chunk.map(processSpread));
        newSpreads.push(...processedChunk);
      }

      await workspace.update.feature(
        {
          feature: "storyboard",
          data: { ...storyboard, spreads: newSpreads },
          note: "Transferred manuscript to storyboard.",
          ai: 0,
        },
        "~157"
      );

      performing.set.updating("success", "~160");
      resolve(true);
    } catch (error) {
      performing.set.updating("error", "~164");
      errors.error(t("errorTransferringManuscript"), error, "~165");
      reject(error);
    }
  });
}

export async function confirmTransfer() {
  const { workspace, errors, t } = this.props;

  const token = workspace.token();

  try {
    let { data } = await axios.post("/api/workspace/usable/designer/trims/trim", {
      token: token,
      feature: "storyboard",
    });

    let range = data?.data?.range;
    let dimensions = data?.data?.dimensions;

    if (!range || !dimensions) throw "No range or dimensions.";

    return { spreads: Math.max(range[0] / 2), dimensions: [dimensions[0] * 2, dimensions[1]] };
  } catch (error) {
    errors.error(t("errrorTransferTrim"), error, "~94");
    return null;
  }
}
