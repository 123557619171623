/** @format */
import isHotkey from "is-hotkey";

export function setupKeydown() {
  return new Promise((resolve) => {
    // Use a named function reference instead of an anonymous function
    if (!this.keydownHandler) {
      this.keydownHandler = (e) => keydown.call(this, e);
      window.addEventListener("keydown", this.keydownHandler);
    }
    resolve(true);
  });
}

export function teardownKeypress() {
  try {
    // Remove the event listener using the same named function reference
    if (this.keydownHandler) {
      window.removeEventListener("keydown", this.keydownHandler);
      this.keydownHandler = null; // Clear the reference
    }
  } catch (_) {}
}

export default function keydown(event) {
  const { ui } = this.props;

  const commandkeys = {
    // Annotations
    "shift+2": "character",
    "shift+3": "imagery",
    "mod+j": "comment",

    // Markers
    "mod+enter": "break",
    "mod+b": "bookmark",

    // Exaping annotations
    escape: "escape",

    // this should be moved to workspace
    "mod+-": "zoomOut",
    "mod+=": "zoomIn", // = is an alias for +
  };

  try {
    // Loop through the hotkey (so we can simplif preveninig defaults and enabling new keys)
    for (const commandkey in commandkeys) {
      // Check if the hotkey is press
      if (isHotkey(commandkey)(event)) {
        // Stop the default stuf
        event.preventDefault();

        // Create switch to hanlde the key commands
        switch (commandkeys[commandkey]) {
          // --- Annotations

          // Characters
          case "character":
            this.annotations.open("character");
            break;

          // Imagery
          case "imagery":
            this.annotations.open("imagery");
            break;

          // Comments
          case "comment":
            this.annotations.open("comment");
            break;

          // Markers

          case "bookmark":
            this.markers.bookmark.insert("~76");
            break;

          // This should be replace with new tools popup
          case "break":
            this.markers.break.insert("~81");
            break;

          // Exits

          // Close down menus and annotations
          case "escape":
            this.annotations.close();
            // Below can be removed when keypresses are added to workspace
            ui.reset();
            break;

          // Move these to workspace

          case "zoomIn":
            console.log("Move to workspace zoomIn", "~96");
            break;

          case "zoomOut":
            console.log("Move to workspace zoomOut", "~100");
            break;

          default:
            break;
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
}
