/** @format */

import { Box, Card, CardMedia, Grid, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCms } from "cms";
import key from "key";
import React from "react";

import { TinyError } from "errors";

export default function (props) {
  return (
    <TinyError>
      <ReviewsList {...props} />
    </TinyError>
  );
}

export function ReviewsList({ length = 1, spacing = 2, width = 6 }) {
  const theme = useTheme();

  const {
    ready: { custom },
    custom: { reviews },
  } = useCms();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  // Early return a Skeleton if CMS data is not ready
  if (!custom) {
    return <Skeleton variant="rectangular" style={{ minHeight: "100px", minWidth: "200px" }} />;
  }

  // Early return null if reviews data is not valid
  if (!reviews || !Array.isArray(reviews)) return null;

  return (
    <Grid container spacing={spacing} direction={isMd ? "row" : "column-reverse"}>
      {reviews.slice(0, length).map((review, index) => (
        <React.Fragment {...key("cms", "blocks", "reviewlist", index)}>
          <Grid item xs={12} md={12} lg={width} xl={width}>
            <Box marginBottom={4}>
              <Typography variant="h6" component="p">
                {review.review}
              </Typography>
              <Box marginTop={{ xs: 2, sm: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  —{review.name}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} lg={width} xl={width}>
            <Box component={Card} boxShadow={4} height={1} width={1}>
              <CardMedia component="img" role="img" height={300} alt={review.name} image={review.image} />
            </Box>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
