/** @format */
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import DynamicIcon from "dynamicicon";
import { useLocales } from "locales";
import React from "react";

import ChecklistComponent from "@Workspace/components/Checklist";

const Checklist = ({ open, toggleChecklist }) => {
  const { t } = useLocales();
  return (
    <Dialog open={open} onClose={toggleChecklist} aria-labelledby="checklist-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle>
        <span>{t("checklist")}</span>
        <IconButton variant="close" size="small" onClick={toggleChecklist}>
          <DynamicIcon icon="fa-times" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ChecklistComponent />
      </DialogContent>
    </Dialog>
  );
};

export default Checklist;
