/** @format */

export default function ButtonConfig() {
  const { workspace, ui } = this.props;
  const feature = workspace.feature.selected();

  // console.log(this.elements.ready(), "~7");

  // if (!this.elements.ready()) return {};

  return {
    /* Select */
    select: {
      onClick: () => {
        this.tool.set("select");
      },
      enabled: () => {
        return true;
      },
      selected: () => {
        return this.tool.get("select");
      },
    },
    /* Scribbble */
    sketch: {
      onClick: () => {
        !this.tool.get("sketch") ? this.tool.set("sketch") : ui.menu.toggle("designer/sketch");
      },
      enabled: () => {
        return true;
      },
      selected: () => {
        return this.tool.get("sketch");
      },
      visible: () => {
        return false;
      },
    },
    /* Text */
    text: {
      onClick: () => {
        // Add the text
        this.edit.text.add();

        // Set that text was the last thing added (artifact)
        this.tool.set("text");
      },
      enabled: () => {
        // It's always enabled
        return true;
      },
      selected: () => {
        // Check if the text tool is selected (artifact)
        return this.tool.get("text");
      },
    },
    /* Align */
    align: {
      onClick: () => {
        ui.close(); // This might be a bug in ui. Close shouldn't need to be here
        ui.menu.toggle("designer/align");
      },
      enabled: () => {
        return this.element.type("text");
      },
    },
    left: {
      enabled: () => {
        return !this.edit.font.align.get("left");
      },
      onClick: () => {
        this.edit.font.align.set("left");
      },
    },
    center: {
      enabled: () => {
        return !this.edit.font.align.get("center");
      },
      onClick: () => {
        this.edit.font.align.set("center");
      },
    },
    right: {
      enabled: () => {
        return !this.edit.font.align.get("right");
      },
      onClick: () => {
        this.edit.font.align.set("right");
      },
    },
    /* Size */
    size: {
      enabled: () => {
        return this.element.type("text");
      },
    },
    "size/up": {
      enabled: () => {
        return this.element.type("text") && this.edit.font.size.get() < 101;
      },
      onClick: () => {
        this.edit.font.size.set("up");
      },
    },
    "size/down": {
      enabled: () => {
        return this.element.type("text") && this.edit.font.size.get() > 1;
      },
      onClick: () => {
        this.edit.font.size.set("down");
      },
    },
    /* Family */
    family: {
      onClick: () => {
        ui.close(); // This might be a bug in ui. Close shouldn't need to be here
        ui.menu.toggle("designer/family");
      },
      enabled: () => {
        return this.element.type("text");
      },
    },
    /* Color */
    color: {
      onClick: () => {
        ui.close(); // This might be a bug in ui. Close shouldn't need to be here
        ui.menu.toggle("designer/color");
      },
      enabled: () => {
        return this.element.type("text");
      },
    },
    /* Media */
    media: {
      enabled: () => {
        return true;
      },
      onClick: () => {
        workspace.feature.select({ feature: "media", element: "board", component: "media" });
      },
    },
    /* More */
    more: {
      enabled: () => {
        return !ui.menu.opened("designer/more");
      },
      onClick: () => {
        ui.menu.toggle("designer/more");
      },
    },
    /* Setup */
    setup: {
      enabled: () => {
        return true;
      },
      onClick: () => {
        workspace.feature.select({ feature: feature, element: "dialog", component: "setup" });
      },
    },
    /* View */
    view: {
      enabled: () => {
        return true;
      },
      onClick: () => {
        ui.menu.toggle("designer/view");
      },
    },
    /* Preview */
    preview: {
      enabled: () => {
        return false;
      },
      visible: () => {
        return false;
      },
      onClick: () => {
        ui.dialog.open("preview");
      },
    },
    elements: {
      enabled: () => {
        return false;
      },
      onClick: () => {},
    },
    /* Undo/Redo */
    undo: {
      enabled: () => {
        return this.dos.can("undo");
      },
      onClick: () => {
        this.dos.undo();
      },
    },
    redo: {
      enabled: () => {
        return this.dos.can("redo");
      },
      onClick: () => {
        this.dos.redo();
      },
    },
    /* Share */
    share: {
      enabled: () => {
        return true;
      },
      onClick: () => {
        ui.dialog.open("share", { from: feature });
      },
    },
    /* Bottom Menu */
    "add/spread": {
      enabled: () => {
        return true;
      },
      onClick: () => {
        this.spread.add("~219");
      },
      visible: () => {
        return true;
      },
    },
    "delete/spread": {
      enabled: () => {
        return true;
      },
      onClick: (_, index) => {
        this.spread.delete(index);
      },
      visible: () => {
        return true;
      },
    },
    /* Zooms */
    zoomIn: {
      enabled: () => {
        return workspace.zoom.can("in");
      },
      onClick: () => {
        workspace.zoom.set("in");
      },
    },
    zoomOut: {
      enabled: () => {
        return workspace.zoom.can("out");
      },
      onClick: () => {
        workspace.zoom.set("out");
      },
    },
    /* Next/Previos spreads */
    next: {
      enabled: () => {
        return this.spreads.get().length > this.spread.number() + 1;
      },
      onClick: () => {
        this.spread.direction("next");
      },
    },
    previous: {
      enabled: () => {
        return this.spread.number() > 0;
      },
      onClick: () => {
        this.spread.direction("previous");
      },
    },
    /* Elements */
    "delete/element": {
      visible: () => {
        return this.element.has();
      },
      enabled: () => {
        return this.element.has();
      },
      onClick: () => {
        this.element.delete();
      },
    },
    /* Reset */
    reset: {
      enabled: () => {
        return this.element.resetable();
      },
      visible: () => {
        return this.element.has();
      },
      onClick: () => {
        this.element.reset();
      },
    },
    /* Layers */
    "layer/up": {
      enabled: () => {
        try {
          return this.edit.layer.get() < this.edit.layers.get() - 1;
        } catch (_) {}
      },
      visible: () => {
        return true;
      },
      onClick: () => {
        this.edit.layer.set.up();
      },
    },
    "layer/down": {
      enabled: () => {
        return this.edit.layer.get() > 0;
      },
      visible: () => {
        return true;
      },
      onClick: () => {
        this.edit.layer.set.down();
      },
    },
    /* Flip */
    "flip/vertical": {
      enabled: () => {
        return true;
      },
      visible: () => {
        return true;
      },
      onClick: () => {
        this.edit.flip("vertical");
      },
    },
    "flip/horizontal": {
      enabled: () => {
        return true;
      },
      visible: () => {
        return true;
      },
      onClick: () => {
        this.edit.flip("horizontal");
      },
    },
    /* tools */
    "tool/warp": {
      enabled: () => {
        return !this.state.edit.warpable;
      },
      selected: () => {
        return this.state.edit.warpable;
      },
      onClick: () => {
        this.tool.set("warp");
      },
    },
    "tool/resize": {
      enabled: () => {
        return this.state.edit.warpable;
      },
      selected: () => {
        return !this.state.edit.warpable;
      },
      onClick: () => {
        this.tool.set("resize");
      },
    },
    "controls/more": {
      enabled: () => {
        return true;
      },
      onClick: () => {
        ui.menu.toggle("controls/more");
      },
    },
    "element/locked": {
      enabled: () => {
        return true;
      },
      onClick: () => {
        try {
          this.element.locked.toggle();
        } catch (_) {}
      },
    },
    "background/contain": {
      enabled: () => {
        try {
          return this.element.selected()?.index == 0;
        } catch (_) {
          return false;
        }
      },
      onClick: () => {
        try {
          this.element.background.set("contain");
        } catch (_) {}
      },
    },
    "background/cover": {
      enabled: () => {
        try {
          return this.element.selected()?.index == 0;
        } catch (_) {
          return false;
        }
      },
      onClick: () => {
        try {
          this.element.background.set("cover");
        } catch (_) {}
      },
    },
    // Remove the background from an image
    "remove/bg": {
      enabled: () => {
        // enabled only when an image and not the background // this.element.index() > 0 &&
        return this.element.type("image");
      },
      onClick: (_, mediaId) => {
        // Close the open controls
        ui.menu.close("controls/more");

        // This will help apply the stuff later
        const selected = this.element.selected();

        const spread = this.spread.number();

        // Open the background remove bg widget
        workspace.feature.select({
          feature: "images",
          element: "widget",
          component: "removebg",
          props: {
            ...selected,
            spread,
            mediaId,
            callback: this.element.image.variations.transparent.set,
            opened: true,
          },
        });
      },
    },
    // Revert to a background
    "restore/bg": {
      enabled: () => {
        // enabled only when an image and not the background // this.element.index() > 0 &&
        return this.element.type("image");
      },
      onClick: () => {
        this.element.image.variations.transparent.restore();
      },
    },
  };
}
