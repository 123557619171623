/** @format */
import { Box, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import DynamicIcon from "dynamicicon";
import FileUploader from "file-uploader";
import { collection } from "firebase/firestore";
import { ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "firemade";
import { withLocales } from "locales";
import uniq from "lodash/uniq";
import { nanoid } from "nanoid";
import { withPerforming } from "performing";
import { Component as ReactComponent } from "react";
import { withSnackbar } from "snackbar";
import { removeStopwords } from "stopword";
import { withUnit } from "unit";
import { withUser } from "user";
import stopwords from "../../config/stopwords.json";
import { withMedia } from "../../context";

const StyledForm = styled("form")(() => ({
  "& .small": {
    width: 28,
    height: 28,
  },

  "& .medium": {
    width: 56,
    height: 56,
  },

  "& .large": {
    width: 96,
    height: 96,
  },

  "& .super": {
    width: 128,
    height: 128,
  },

  "& .duper": {
    width: 256,
    height: 256,
  },
}));

class Uploader extends ReactComponent {
  constructor(props) {
    super(props);
    this.unit = this.props.unit.new(this.constructor.name);
    this.state = {
      tab: 0,
      uploading: false,
      progress: 0,
    };

    this.storageRef = ref(storage, this.props.directory);
    this.refForUploader = this.storageRef;
    this.refForUploader["child"] = function (filename) {
      const newRef = ref(this, filename);
      newRef["put"] = function (file, metadata) {
        return uploadBytesResumable(newRef, file, metadata);
      };
      return newRef;
    };

    // The types we can upload
    this.types = {
      image: {
        accept: "image/png, image/jpg, image/jpeg",
        friendly: "Image",
      },
      inspiration: {
        accept: "image/png, image/jpg, image/jpeg",
        friendly: "Image",
      },
      audio: {
        accept: "audio/mp3",
        friendly: "Audio",
      },
      video: {
        accept: "audio/mp4",
        friendly: "Video",
      },
      document: {
        accept:
          "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/epub+zip",
        friendly: "Audio",
      },
    };

    // Handles file upload name because the fucking lib doesn't
    this.uploadingFiles = [];
    this.log = {};
  }

  // When the media upload starts - COMPLETE
  start = (event) => {
    this.props.performing.set.updating("updating", "~112");

    this.uploadingFiles.push(event.name);

    this.setState({ uploading: true, progress: 0 });
  };

  // Checking progress for uploaded media - COMPLETE
  progress = (progress) => {
    this.setState({ progress: progress });
  };

  // If there's an error when uploading media - COMPLETE
  error = () => {
    const { t } = this.props;

    this.setState({ uploading: false, progress: 100 });

    this.props.snackbar.open(t("unexpectedError"), "error");
  };

  // When media has update correctly - COMPLETE
  success = async (url, collections, type, attachedTo) => {
    const { media } = this.props;
    let tmp = this;

    let filename = Object.keys(this.log)[0].replace(/\.[^/.]+$/, "");
    let [id, extension] = filename.split(".");

    function extractTagsFromFilename(file) {
      // Step 1: URL Decode the filename
      const decodedFilename = decodeURIComponent(file);

      // Step 2: Use regular expressions to split the filename into words
      const words = decodedFilename.toLowerCase().match(/[^\s-_]+/g) || [];

      // Step 3: Filter out stopwords
      const filteredWords = removeStopwords(removeStopwords(words), stopwords);

      // Step 4: Remove duplicates, short tags, and sort the tags
      const tags = uniq(filteredWords.filter((word) => word.length >= 3)).sort();

      return tags;
    }

    let tags = extractTagsFromFilename(filename);

    if (typeof attachedTo == "string") attachedTo = [attachedTo];
    if (typeof collections == "string") collections = [collections];

    try {
      this.uploadingFiles.shift();
      if (type == "image") {
        const imgLoadPromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = url;
        });

        const img = await imgLoadPromise;

        const payload = {
          id: id,
          userId: this.props.userId || this.props.user.id || null,
          attachedTo: attachedTo,
          collections: collections,
          tags: tags.filter(Boolean),
          meta: { width: img.width, height: img.height },
          url: url,
          type: type,
          directory: this.props.directory,
          shared: this.props.shared || true,
          file: id + "." + extension,
          variations: { clco: url, cluc: url },
          timestamp: {
            created: Date.now(),
            updated: Date.now(),
          },
          query: this.props.query,
        };

        await media.save(payload, "~209");
      } else {
        this.props.onSuccess({
          id: id,
          userId: this.props.user.id || null,
          attachedTo: attachedTo,
          collection: collection,
          meta: {},
          url: url,
          type: type,
          file: file,
          tags: tags,
          processed: processed,
        });
      }
      if (this.uploadingFiles.length == 0) {
        this.setState({ uploading: false, progress: 100 });
        this.props.performing.set.updating("success", "~234");
      }
    } catch (error) {
      this.props.snackbar.open(this.props.t("unexpectedError"), "error");
    }
  };

  render() {
    let { size, text, type, collections, attachedTo, onSuccess, onError, onStart } = this.props;

    if (!size) size = "medium";

    function singularize(str) {
      if (str.endsWith("s")) {
        return str.slice(0, -1);
      }
      return str;
    }

    type = singularize(type);

    let icons = {
      small: 8,
      medium: 15,
      large: 30,
      super: 40,
      duper: 128,
    };

    return (
      <StyledForm>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button color="success" variant="contained" disabled={this.state.uploading} sx={{ minWidth: "48px" }}>
            <FileUploader
              accept={this.types[type].accept}
              name={type}
              multiple={true}
              filename={(file) => {
                let id = nanoid();
                let extension = file.name.split(".").pop();
                let filename = id + "." + extension;
                this.log[filename] = file;
                return filename;
              }}
              randomizeFilename={true}
              storageRef={this.refForUploader}
              onUploadStart={(event) => {
                this.start(event, type);
                if (onStart) onStart({ event, type });
              }}
              onUploadError={(error) => {
                this.error(error);
                if (onError) onError({ filename, collections, type, attachedTo });
              }}
              onUploadSuccess={async (filename) => {
                let id = filename.split(".").shift();
                await this.success(filename, collections, type, attachedTo);
                if (onSuccess) onSuccess({ id, filename, collections, type, attachedTo });
              }}
              onProgress={(progress) => this.progress(progress)}
              children={
                <>
                  {!this.state.uploading ? (
                    <>
                      {text ? (
                        <>{text}</>
                      ) : (
                        <DynamicIcon
                          icon={"fa-upload"}
                          style={{
                            fontSize: icons[size] + "px",
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <CircularProgress size={icons[size] * 0.75} />
                  )}
                </>
              }
            />
          </Button>
        </Box>
      </StyledForm>
    );
  }
}

export default withSnackbar(withUnit(withPerforming(withUser(withMedia(withLocales(Uploader))))));
