/** @format */

import { doc, setDoc } from "firebase/firestore";
import { db } from "firemade";
import { nanoid } from "nanoid";

async function save({
  id = nanoid(),
  type = "",
  collections = [],
  attachedTo = [],
  tags = [],
  meta = {},
  url,
  file,
  variations,
  directory,
  shared = false,
  userId,
  query,
}) {
  try {
    let timestamp = Date.now();
    let payload = {
      id: id,
      userId: userId,
      type: type,
      meta: meta,
      collections: collections,
      attachedTo: attachedTo,
      variations: variations,
      tags: tags,
      shared: shared,
      url: url,
      directory: directory,
      filename: file,
      timestamp: {
        created: timestamp,
        updated: timestamp,
      },
    };

    // Update the state with the new collections and add the new media to the appropriate query
    this.setState((prevState) => {
      const newQueries = {
        ...prevState.queries,
        ...collections.reduce((acc, collection) => ({ ...acc, [collection]: [] }), {}),
      };

      // If a query is provided, add the new media to that query
      if (query && newQueries[query]) {
        newQueries[query] = [...newQueries[query], payload];
      }

      // Also add the new media to all collection queries it belongs to
      collections.forEach((collection) => {
        if (newQueries[collection]) {
          newQueries[collection] = [...newQueries[collection], payload];
        }
      });

      return { queries: newQueries };
    });

    await setDoc(doc(db, "media", id), payload);

    return payload;
  } catch (error) {
    console.error("Save error:", error);
    this.onError(error);
    throw error;
  }
}

export { save };
