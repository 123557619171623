/** @format */
import Config from "@Workspace/config";
import capitalize from "capitalize";
import { lazy } from "react";
import { Functional } from "unit";

const unit = Functional.unit("SetupMedia");

export default async function Setup(props, from = "~12") {
  unit.report({
    method: "setupManuscript",
    message: "Setting up desk from " + from + ".",
    test: "Workspace should begin to load showing progress as each individual component loads.",
  });
  return await new Promise(async (resolve, reject) => {
    try {
      const types = await setupElements(props, from)
        .then((response) => {
          unit.report({
            method: "setupElements",
            message: "Setting up elements.",
            test: "Different media types should be available in panel.",
          });
          return response;
        })
        .catch((error) => {
          console.error(error, "~30");
          throw error;
        });
      // Add anymore setup here if needed
      if (types) resolve(types || {});
    } catch (error) {
      console.error(error, "~36");
      reject(error, "~37");
    }
  });
}

async function setupElements(from = "~42") {
  let types = {};
  return await new Promise(async (resolve, reject) => {
    try {
      unit.report({
        from: from,
        method: "setupMedia",
        event: "setupMedia",
        payload: { from: "workspace" },
        test: "Media already in the spreads or media panel should be visible.",
      });
      if (!Config?.usable?.config?.elements?.types) return resolve(types);

      Config.usable.config.elements.types.forEach((type) => {
        types[type.slug] = {
          ...{
            icon: type.icon,
            title: type.title,
            visible: type.visible,
            enabled: type.enabled,
          },
          panel: lazy(() =>
            import(
              `../../../../../../../Features/components/${capitalize(type.slug)}/panels/${capitalize(
                type.slug
              )}/index.jsx`
            )
          ),
        };
      });
      resolve(types);
    } catch (error) {
      console.error(error);
      reject({}, "~75");
    }
  });
}
