/**
 * Text
 *
 * @format
 */
import { nanoid } from "nanoid";
import { Frame } from "scenejs";
import { Functional } from "unit";
import TextTemplate from "../../config/templates/text.json";

const unit = Functional.unit("designer/text");

/* Add */

export function addTextElement(from = "~15") {
  unit.report({
    method: "addTextElement",
    action: true,
    analyze: true,
    test: "Creating text should be available in double-click or drag.",
    from: from,
  });

  const { t, workspace, errors } = this.props;

  // Generate random offset between -200 and 200
  const randomOffset = (max = 200) => Math.floor(Math.random() * (max + max + 1)) - max;

  return new Promise((resolve, reject) => {
    try {
      // A unique id for the element
      const id = nanoid();

      // The language this text is in
      const language = workspace.language.get();

      // The dimenions we want the element (this could be moved into the template file)
      const { width, height } = TextTemplate.style[0];

      // Get the editor width so we can place the element in the center
      const { width: editorWidth, height: editorHeight } = this.editor.style();

      // Center the element
      const textLeft = parseInt((editorWidth - width) / 2) + randomOffset();
      const textHeight = parseInt((editorHeight - height) / 2) + randomOffset();

      const element = {
        ...TextTemplate,
        id: id,
        font: { ...TextTemplate.font, family: this.families.get()[0] },
        text: { [language]: t("defaultDesignerText") },
        style: [
          {
            ...TextTemplate.style[0],
            width: width,
            height: height,
            left: textLeft,
            top: textHeight,
          },
        ],
      };

      this.element.add(element).then(() => {
        resolve();
      });
    } catch (error) {
      errors.error(t("unexpectedError"), error, "~67");
      reject(error);
    }
  });
}

/* Style */

export function getElementTextStyle(props, from = "~75") {
  unit.report({
    method: "getElementTextStyle",
    message: "Got text style by index.",
    payload: props,
    from: from,
  });

  const exit = () => {
    return new Frame({ display: "none" }).toCSSObject();
  };

  const makeNumeric = (value) => {
    const cleaned = value.toString().replace(/[^0-9.-]/g, "");
    const num = parseFloat(cleaned);
    return isNaN(num) ? 32 : num;
  };

  try {
    const font = this.element.props(props)?.font;

    if (!font) return exit("Missing element.");

    return new Frame({
      fontFamily: font.family,
      fontSize: makeNumeric(font.size),
      color: font.color,
      whiteSpace: "pre-line",
      textAlign: font.align || "left",
      position: "relative",
      outline: "none",
    }).toCSSObject();
  } catch (error) {
    return exit(error);
  }
}

export function onTextStartEditing(event, from = "~112") {
  unit.report({
    method: "onTextStartEditing",
    message: "The text element is made availabe for editing.",
    from: from,
  });
  try {
    event.target.contentEditable = true;
    event.target.classList.add("editing");
    event.target.focus();
    this.setState({ writing: true });
  } catch (e) {
    console.error(e);
    return false;
  }
}

export function onTextEndEditing(event, from = "~129") {
  unit.report({
    method: "onTextEndEditing",
    message: "Text has been edited and focus from text element removed.",
    text: "Select text and change the text. Text should be clickable and saved when the page is refereshed the text is saved.",
    from: from,
  });

  const exit = (error) => {
    // console.log(error);
  };

  const { workspace, performing } = this.props;

  try {
    this.setState({ writing: false });

    this.tool.set("select");

    event.target.contentEditable = false;
    event.target.classList.remove("editing");

    let data = this.data("~151");

    const spread = this.state?.spread?.number || 0;
    const index = this.element.selected()?.index;
    const language = workspace.language.get();

    if (!data?.spreads[spread]?.elements[index]) exit();

    data.spreads[spread].elements[index].text[language] = event.target.textContent;

    // Update the book
    this.project
      .update(
        {
          data: data,
          note: "Updated text.",
          ai: 0,
        },
        "~169"
      )
      .then(() => {
        // performing.set.updating("success", "~172");
      })
      .catch(() => {
        performing.set.updating("error", "~175");
      });

    setTimeout(() => this.editor.refresh(), 100);
  } catch (error) {
    return false;
  }
}

export function setTextSize(props, from = "~184") {
  unit.report({
    method: "setTextSize",
    message: "Sets the size of the text.",
    analyze: true,
    action: true,
    test: "When typing text, the size of the element should be calculate.",
    payload: props,
    from: from,
  });
  try {
    const { index, id } = props;
    const data = this.data("~196");
    const spread = this.state?.spread?.number || 0;
    const frame = this.state?.frame?.number || 0;

    // Make sure the element exists
    if (!data?.spreads?.[spread]?.elements?.[index]?.style?.[frame]) return;

    // get the moveable element
    const element = document.getElementById(id);

    // Get the text element wrapped inside
    const text = element.querySelector('[data-group-type="text"]');

    // Get the height from it
    const { offsetWidth: width, offsetHeight: height } = text;

    // Merge the new height and width
    data.spreads[spread].elements[index].style[frame] = {
      ...data.spreads[spread].elements[index].style[frame],
      height,
      width,
    };

    // Do this quickly
    element.style.height = `${height}px`;
    element.style.width = `${width}px`;

    // Update the state
    this.setState({ data: data });
  } catch (_) {}
}

export function getTextSize(props, from = "~228") {
  unit.report({
    method: "setTextSize",
    message: "getTextSize the new size of the text box.",
    analyze: true,
    action: true,
    test: "When resizing a text element, this should assist in sizing the box.",
    payload: props,
    from: from,
  });
  try {
    const { index, id } = props;
    const data = this.data("~240");
    const spread = this.state?.spread?.number || 0;
    const frame = this.state?.frame?.number || 0;

    // Make sure the element exists
    if (!data?.spreads?.[spread]?.elements?.[index]?.style?.[frame]) return;

    // get the moveable element
    const element = document.getElementById(id);

    // Get the text element wrapped inside
    const text = element.querySelector('[data-group-type="text"]');

    // Get the height from it
    let { offsetHeight: height, offsetWidth: width } = text;

    // Limit the width of the text
    if (width < 300) width = 300;

    return { height, width };
  } catch (_) {}
}
