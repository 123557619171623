/** @format */
import React from "react";

import Container from "@Web/Container";
import Main from "@Web/Layouts/Main";
import { Box, Divider, Grid } from "@mui/material";
import Cdn from "cdn";
import key from "key";
import { withLocales } from "locales";
import { nanoid } from "nanoid";
import { spread as template, trims } from "workspace";

const TrimGuide = (props) => {
  const { t } = props.locales;

  const getSpreads = (trim) => {
    return new Array(trim.range[0] / 2).fill(template).map((s) => ({ ...s, id: nanoid() }));
  };

  const getOrderedTrims = () => {
    let orderedTrims = [...trims];

    orderedTrims.sort((a, b) => {
      // Then sort by popularity
      return a.available === b.available ? 0 : a.available ? -1 : 1;
    });

    return orderedTrims.map((trim) => ({ ...trim, spreads: getSpreads(trim) }));
  };

  return (
    <Main>
      <Box
        sx={{
          width: 1,
          height: 1,
          overflow: "hidden",
          marginTop: "100px",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <h1>{t("trimSizesTitle")}</h1>
          <h5>{t("trimSizesSubTitle")}</h5>
        </Box>
        <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: "95%" }}>
          <Box display={"flex"} flexDirection={{ xs: "column", md: "row" }} position={"relative"}>
            <Box width={1} order={{ xs: 2, md: 1 }} display={"flex"} alignItems={"center"}>
              <Container>
                <Box sx={{ margin: "0px 0 20px" }}>
                  <Grid container alignItems="center" justifyContent="center" spacing={5}>
                    {getOrderedTrims().map((trim, i) => {
                      // Get original dimensions
                      const [width, height] = trim.dimensions;

                      // Determine scale factor
                      const maxDimension = Math.max(width, height);
                      const scaleFactor = 100 / maxDimension;

                      // Calculate scaled dimensions
                      let scaledWidth = width * scaleFactor;
                      let scaledHeight = height * scaleFactor;

                      if (i < 3) {
                        scaledWidth *= 2.5;
                        scaledHeight *= 2.5;
                      }

                      return (
                        <React.Fragment {...key("onboard", "design", "slugs", trim, i)}>
                          <Grid
                            item
                            xs={i < 3 ? 4 : 3}
                            sx={{
                              display: "flex",
                              flexDirection: "column", // This was corrected from 'direction' to 'flexDirection'
                              justifyContent: "center",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                marginTop: "10px",
                                width: `${scaledWidth}px`,
                                height: `${scaledHeight}px`,
                                borderRadius: "3px",
                                border: "1px solid rgba(12,12,12,.3)",
                                position: "relative",
                                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)", // This adds a small drop shadow
                                backgroundImage: `url(${Cdn(
                                  "https://us-central.cdn.made.live//thumbnail_children_s_book_characters_4717739092/thumbnail_children_s_book_characters_4717739092.png?width=1728&height=864"
                                )})`,
                                cursor: trim.available ? "pointer" : "inherit",
                                backgroundColor: "#FAFAfA",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                opacity: trim.available ? 1 : 0.3,
                              }}
                              onClick={trim.available ? () => onSelect(trim) : () => {}}
                            >
                              <Box
                                sx={{
                                  fontSize: i < 3 ? "24px" : "8px",
                                  textAlign: "center",
                                  position: "absolute",
                                  top: "10px",
                                  width: "100%",
                                  fontWeight: "bold",
                                  opacity: 0.6,
                                }}
                                component="span"
                              >
                                {t("title")}
                              </Box>
                              <Box
                                sx={{
                                  fontSize: "8px",
                                  textAlign: "center",
                                  position: "absolute",
                                  bottom: "10px",
                                  width: "100%",
                                  fontWeight: "bold",
                                  opacity: 0.5,
                                }}
                                component="span"
                              >
                                {t("authorNameShort")}
                              </Box>
                            </Box>
                            <Divider />
                            <Box sx={{ opacity: trim.available ? 1 : 0.3 }}>
                              <Box sx={{ fontSize: "10px", marginTop: "10px" }}>{trim.name}</Box>
                              <Box sx={{ fontSize: "10px" }}>
                                {trim.range.join("-")} {t("pages")}.
                              </Box>
                              <Box sx={{ fontSize: "10px" }}>
                                <Box component="span">{t("availableFrom")}</Box>
                                {trim.partners &&
                                  trim.partners.map((partner, j) => (
                                    <Box
                                      sx={{ textTransform: "uppercase", marginLeft: "3px", fontWeight: "bold" }}
                                      component="span"
                                      {...key("onboard", "design", "partners", trim.slug, partner, j)}
                                    >
                                      {partner}
                                    </Box>
                                  ))}
                                .
                              </Box>
                            </Box>
                          </Grid>
                          {i == 2 && (
                            <Grid item xs={12} {...key("onboard", "design", "divider")}>
                              <Box sx={{ padding: "10px 20%", textAlign: "center" }}>
                                <Divider />
                                <Box sx={{ padding: "5%" }}>{t("Coming Soon")}</Box>
                              </Box>
                            </Grid>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </Box>
              </Container>
            </Box>
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export default withLocales(TrimGuide);

// export default function ComparisonTable() {
//   return <div></div>;
// }
