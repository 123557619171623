/** @format */

import { Box, Card, Container, Grid, useTheme } from "@mui/material";
import "chart.js/auto";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { db } from "firemade";
import { Working } from "performing";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import Notices from "./components/Notices";

const Monitor = () => {
  const [statuses, setStatuses] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    const fetchStatuses = async () => {
      const q = query(collection(db, "monitor"), orderBy("timestamp.created", "desc"), limit(600));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const allStatuses = querySnapshot.docs.map((doc) => doc.data());

        setStatuses(allStatuses);
      }
    };

    fetchStatuses();
  }, []);

  const types = ["Web", "CMS", "API", "Backend"];

  // Reverse the data for oldest to newest representation in the graph
  const reversedStatuses = [...statuses].reverse();

  const createDataset = (type) => {
    return {
      data: reversedStatuses.map((status) => (status[type.toLowerCase()] ? 1 : 1)),
      backgroundColor: reversedStatuses.map((status) =>
        status[type.toLowerCase()] ? theme.palette.success.main : theme.palette.error.main
      ),
    };
  };

  // Function to format the timestamp for labels
  const formatTimestamp = (timestamp) => {
    // Assume the timestamp is in milliseconds
    const date = new Date(timestamp.created);
    return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  };

  // Just make the status services nicer
  const friendlyType = (type) => {
    switch (type) {
      case "Web":
        return "Workspace";
      case "CMS":
        return "Website";
      case "API":
        return "API";
      case "Backend":
        return "Services";
      default:
        return type;
    }
  };

  return (
    <Container sx={{ marginTop: "40px" }}>
      <Card sx={{ padding: "40px 40px 40px 20px" }}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          {statuses.length > 0 ? (
            types.map((type) => (
              <React.Fragment key={type}>
                <Grid item xs={2}>
                  <h5 sx={{ margin: 0 }}>{friendlyType(type)}</h5>
                </Grid>
                <Grid item xs={10}>
                  <Box sx={{ maxHeight: "40px", width: "100%", overflowX: "auto" }}>
                    <Bar
                      data={{
                        labels: reversedStatuses.map((status) => formatTimestamp(status.timestamp)), // Updated
                        datasets: [createDataset(type)],
                      }}
                      height={40}
                      options={{
                        maintainAspectRatio: false,
                        scales: {
                          y: {
                            display: false,
                            ticks: {
                              beginAtZero: true,
                              max: 1,
                            },
                          },
                          x: {
                            display: false, // Changed to true to display the labels
                          },
                        },
                        plugins: {
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            enabled: false, // Turn off the tooltips
                          },
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <Working />
          )}
        </Grid>
      </Card>
    </Container>
  );
};

export { Monitor, Notices };
